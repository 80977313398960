import _ from 'lodash'
import moment from 'moment-timezone'
import { translate } from 'translator'

export const ExportWarningMessage = 'You need to search text or filter first before exporting results.'
export const MAX_EXPORT_RECORDS = 500
export const MEDICAL_NEGLIGENCE = 'Medical Negligence'
export const PrepaymentLabel = 'Payment'
export const TenantName = {
  OTBYMAG: 'OTBYMAG',
}
export const PubSubTopics = {
  SelectCaseNo: 'SelectCaseNo',
}
export const YES_NO_OPTIONS = [
  { displayName: `All`, id: null },
  { displayName: `Yes`, id: true },
  { displayName: `No`, id: false },
]

export const SESSION_BOOKED_FILTER = [
  { displayName: `All`, id: null },
  { displayName: `Booked`, id: true },
  { displayName: `Available`, id: false },
]
export const CALENDAR_SESSION_BOOKED_FILTER = [
  { displayName: `All`, id: 0 },
  //{ displayName: `Booked`, id: 1 },
  //{ displayName: `Available`, id: 2 },
  { displayName: `Unbooked (CA)`, id: 3 },
  { displayName: `Unbooked (UA)`, id: 4 },
  { displayName: `Booked (Confirmed)`, id: 5 },
  { displayName: `Booked (Unconfirmed)`, id: 6 },
]
export const SEARCH_SESSION_BOOKED_FILTER = [
  { displayName: `All`, id: null },
  { displayName: `Booked`, id: true },
  { displayName: `Available`, id: false },
]
export const PIC_GROUP_TYPE = {
  REQUEST_RECEIVED: 'REQUEST_RECEIVED',
  APPOINTMENT: 'APPOINTMENT',
  PAPERWORK: 'PAPERWORK',
  ATTENDANCE: 'ATTENDANCE',
  CANCELLATION: 'CANCELLATION',
  REPORT_WRITING: 'REPORT_WRITING',
  FURTHER_REVIEW: 'FURTHER_REVIEW',
  POST_ADMIN: 'POST_ADMIN',
  QUALITY_ASSURANCE: 'QUALITY_ASSURANCE',
  REPORT_FINALISATION: 'REPORT_FINALISATION',
  DICTATION: 'DICTATION',
  REPORT_DRAFT: 'REPORT_DRAFT',
  REPORT_CORECTED: 'REPORT_CORECTED',
  REPORT_FINAL: 'REPORT_FINAL',
  OT_QUOTATION: 'OT_QUOTATION',
  PRE_PAYMENT: 'PRE_PAYMENT',
}

export const INTERPRETER_STATUS_ENUM = {
  NONE: 'NONE',
  CONFIRMED: 'CONFIRMED',
  CANCELLED_NO_FEE: 'CANCELLED_NO_FEE',
  CANCELLED_WITH_FEE: 'CANCELLED_WITH_FEE',
}

export const ActiveFilterOptions = [
  { displayName: 'All', id: null },
  { displayName: 'Active', id: true },
  { displayName: 'Inactive', id: false },
]
export const QUOTE_OPTIONS = [
  { displayName: 'All', id: null },
  { id: true, displayName: 'True' },
  { id: false, displayName: 'False' },
]

export const BUSINESS_TYPE_OPTIONS = [
  { id: 'EMPLOYER', displayName: 'Employer' },
  { id: 'INSURER', displayName: 'Insurer' },
  { id: 'SELF_INSURER', displayName: 'Self Insurer' },
  { id: 'PUBLIC_INSURER', displayName: 'Public Insurer' },
  { id: 'LAW_DEFENDANT', displayName: 'Law Defendant' },
  { id: 'LAW_PLAINTIFF', displayName: 'Law Plaintiff' },
  { id: 'LAW_PRACTICE', displayName: 'Law Practice' },
  { id: 'INTERPRETER_PROVIDER', displayName: 'Interpreter Provider' },
  { id: 'CLIENT_ACQUISITIONS', displayName: 'Client Acquisitions' },
  { id: 'MLP_SPECIALIST', displayName: 'MLP/Specialist' },
  { id: 'INTERPRETER_PARTNER', displayName: 'Interpreter Partner' },
  { id: 'SUPPORT_COORDINATION', displayName: 'Support Coordination' },
  { id: 'FUNDING_MANAGER', displayName: 'Funding Manager' },
  { id: 'OTHER', displayName: 'Other' },
]

export enum BUSINESS_TYPE {
  INSURER = 'INSURER',
  SELF_INSURER = 'SELF_INSURER',
  PUBLIC_INSURER = 'PUBLIC_INSURER',
  LAW_DEFENDANT = 'LAW_DEFENDANT',
  LAW_PLAINTIFF = 'LAW_PLAINTIFF',
  LAW_PRACTICE = 'LAW_PRACTICE',
  INTERPRETER_PROVIDER = 'INTERPRETER_PROVIDER',
  CLIENT_ACQUISITIONS = 'CLIENT_ACQUISITIONS',
  // MLP = 'MLP',
  OTHER = 'OTHER',
  SUPPORT_COORDINATION = 'SUPPORT_COORDINATION',
  FUNDING_MANAGER = 'FUNDING_MANAGER',
}

export const BusinessTypeFilterOptions = [{ displayName: 'All', id: '' }, ..._.cloneDeep(BUSINESS_TYPE_OPTIONS)]

export const businessTypeObj = {
  INSURER: 'Insurer',
  LAW_DEFENDANT: 'Law Defendant',
  LAW_PLAINTIFF: 'Law Plaintiff',
  LAW_PRACTICE: 'Law Practice',
  SELF_INSURER: 'Self Insurer',
  INTERPRETER_PROVIDER: 'Interpreter Provider',
  OTHER: 'Other',
}

export enum EMAIL_RECEIVER_ALIAS {
  CaseManager_Booking = 'CaseManager_Booking',
  CaseManager_Paying = 'CaseManager_Paying',
  Specialist_Booking = 'Specialist_Booking',
  Staff_Booking = 'Staff_Booking',
  Contractor = 'Contractor',
  System_User = 'System_User',
  Client = 'Client',
  Client_SMS = 'Client_SMS',
  Interpreter_Provider = 'Interpreter_Provider',
  Staff_Pic = 'Staff_Pic',
  MR_Provider = 'MR_Provider',
  Medical_Specialist = 'Medical_Specialist',
  Centre = 'Centre',
}

export const EmailReceiverTypeOptions = [
  { displayName: 'All', id: '' },
  { displayName: 'Case Manager (Booking)', id: EMAIL_RECEIVER_ALIAS.CaseManager_Booking },
  { displayName: 'Case Manager (Paying)', id: EMAIL_RECEIVER_ALIAS.CaseManager_Paying },
  { displayName: 'Specialist (Booking)', id: EMAIL_RECEIVER_ALIAS.Specialist_Booking },
  { displayName: 'Staff (Booking)', id: EMAIL_RECEIVER_ALIAS.Staff_Booking },
  { displayName: 'Client', id: EMAIL_RECEIVER_ALIAS.Client },
  { displayName: 'Client (SMS)', id: EMAIL_RECEIVER_ALIAS.Client_SMS },
  { displayName: 'Clinic Centre', id: EMAIL_RECEIVER_ALIAS.Centre },
  { displayName: 'MR Provider', id: EMAIL_RECEIVER_ALIAS.MR_Provider },
  { displayName: 'Interpreter Provider', id: EMAIL_RECEIVER_ALIAS.Interpreter_Provider },
  { displayName: 'Medical Specialist', id: EMAIL_RECEIVER_ALIAS.Medical_Specialist },
  { displayName: 'Staff PIC', id: EMAIL_RECEIVER_ALIAS.Staff_Pic },
  { displayName: 'System User', id: EMAIL_RECEIVER_ALIAS.System_User },
  { displayName: 'Contractor', id: EMAIL_RECEIVER_ALIAS.Contractor },
]

export const CASE_STATUS = {
  CASE_CREATED: 'CASE_CREATED',
  APPOINTMENT_REQUESTED: 'APPOINTMENT_REQUESTED',
  APPOINTMENT_CONFIRMED: 'APPOINTMENT_CONFIRMED',
  PAPERWORK_RECEIVED: 'PAPERWORK_RECEIVED',
  // BRIEF: 'BRIEF',
  FILE_REVIEW: 'FILE_REVIEW',
  ATTENDED: 'ATTENDED',
  NOT_ATTEND: 'NOT_ATTEND',
  OT_ATTEND: 'NOT_ATTEND',
  DICTATION_RECEIVED: 'DICTATION_RECEIVED',
  REPORT_DRAFT_RECEIVED: 'REPORT_DRAFT_RECEIVED',
  REPORT_RETURNED: 'REPORT_RETURNED',
  REPORT_WRITING: 'REPORT_WRITING',
  FUTHER_MATERIAL_REVIEW: 'FUTHER_MATERIAL_REVIEW',
  POST_ASSESSMENT_ADMIN: 'POST_ASSESSMENT_ADMIN',
  QUALITY_ASSURANCE: 'QUALITY_ASSURANCE',
  REPORT_FINALISATION: 'REPORT_FINALISATION',
  REPORT_COMPLETED: 'REPORT_COMPLETED',
  CANCELLED_NO_FEE: 'CANCELLED_NO_FEE',
  CANCELLED_WITH_FEE: 'CANCELLED_WITH_FEE',
  CANCELLED_SPECIALIST_TBP: 'CANCELLED_SPECIALIST_TBP',
  CLIENT_INVOICED: 'CLIENT_INVOICED',
  SPECIALIST_INVOICED: 'SPECIALIST_INVOICED',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  FULLY_PAID: 'FULLY_PAID',
  CLIENT_PARTIALLY_PAID: 'CLIENT_PARTIALLY_PAID',
  SPECIALIST_PARTIALLY_PAID: 'SPECIALIST_PARTIALLY_PAID',
  CLIENT_FULLY_PAID: 'CLIENT_FULLY_PAID',
  SPECIALIST_FULLY_PAID: 'SPECIALIST_FULLY_PAID',
  CLARIFICATION_NO_FEE: 'CLARIFICATION_NO_FEE',
  ONHOLD: 'ONHOLD',
}
export const IME_STATUS = {
  IME_CASE_CREATED: 'IME_CASE_CREATED',
  IME_APPOINTMENT_REQUESTED: 'IME_APPOINTMENT_REQUESTED',
  IME_APPOINTMENT_CONFIRMED: 'IME_APPOINTMENT_CONFIRMED',
  IME_PAPERWORK_RECEIVED: 'IME_PAPERWORK_RECEIVED',
  IME_FILE_REVIEW: 'IME_FILE_REVIEW',
  IME_ATTENDED: 'IME_ATTENDED',
  IME_NOT_ATTEND: 'IME_NOT_ATTEND',
  IME_DICTATION_RECEIVED: 'IME_DICTATION_RECEIVED',
  IME_REPORT_DRAFT_RECEIVED: 'IME_REPORT_DRAFT_RECEIVED',
  IME_REPORT_RETURNED: 'IME_REPORT_RETURNED',
  IME_REPORT_WRITING: 'IME_REPORT_WRITING',
  IME_FUTHER_MATERIAL_REVIEW: 'IME_FUTHER_MATERIAL_REVIEW',
  IME_POST_ASSESSMENT_ADMIN: 'IME_POST_ASSESSMENT_ADMIN',
  IME_QUALITY_ASSURANCE: 'IME_QUALITY_ASSURANCE',
  IME_REPORT_FINALISATION: 'IME_REPORT_FINALISATION',
  IME_REPORT_COMPLETED: 'IME_REPORT_COMPLETED',
  IME_CANCELLED_NO_FEE: 'IME_CANCELLED_NO_FEE',
  IME_CANCELLED_WITH_FEE: 'IME_CANCELLED_WITH_FEE',
  IME_CANCELLED_SPECIALIST_TBP: 'IME_CANCELLED_SPECIALIST_TBP',
  IME_CLIENT_INVOICED: 'IME_CLIENT_INVOICED',
  IME_SPECIALIST_INVOICED: 'IME_SPECIALIST_INVOICED',
  IME_PARTIALLY_PAID: 'IME_PARTIALLY_PAID',
  IME_FULLY_PAID: 'IME_FULLY_PAID',
  IME_CLIENT_PARTIALLY_PAID: 'IME_CLIENT_PARTIALLY_PAID',
  IME_SPECIALIST_PARTIALLY_PAID: 'IME_SPECIALIST_PARTIALLY_PAID',
  IME_CLIENT_FULLY_PAID: 'IME_CLIENT_FULLY_PAID',
  IME_SPECIALIST_FULLY_PAID: 'IME_SPECIALIST_FULLY_PAID',
}

export const IME_STATUS_LABEL = {
  IME_CASE_CREATED: 'Case Created',
  IME_APPOINTMENT_REQUESTED: 'Appointment Requested',
  IME_APPOINTMENT_CONFIRMED: 'Appointment Confirmed',
  IME_PAPERWORK_RECEIVED: 'Paperwork Sent to Specialist',
  IME_FILE_REVIEW: 'File Review',
  IME_ATTENDED: 'Appointment Attended',
  IME_NOT_ATTEND: 'Appointment Not Attended',
  IME_DICTATION_RECEIVED: 'Dictation Received from Specialist',
  IME_REPORT_DRAFT_RECEIVED: 'Report Draft Sent to Specialist',
  IME_REPORT_RETURNED: 'Report Received from Specialist',
  IME_REPORT_WRITING: 'Collect Report from Specialist',
  IME_FUTHER_MATERIAL_REVIEW: 'Further Material Review',
  IME_POST_ASSESSMENT_ADMIN: 'Post Assessment Admin',
  IME_QUALITY_ASSURANCE: 'Quality Assurance',
  IME_REPORT_FINALISATION: 'Report Finalisation',
  IME_REPORT_COMPLETED: 'Report Sent to CMs (Chargeable)',
  IME_CANCELLED_NO_FEE: 'Cancelled (No Fee)',
  IME_CANCELLED_SPECIALIST_TBP: 'Cancelled (No Fee-Pay Specialist)',
  IME_CANCELLED_WITH_FEE: 'Cancelled (Chargeable)',
  IME_PARTIALLY_PAID: 'Partially Paid',
  IME_FULLY_PAID: 'Service Paid',

  IME_CLIENT_INVOICED: 'Customer Invoice Processed',
  IME_CLIENT_PARTIALLY_PAID: 'Customer Invoice Partially Paid',
  IME_CLIENT_FULLY_PAID: 'Customer Invoice Fully Paid',

  IME_SPECIALIST_INVOICED: 'Provider Payment Processed',
  IME_SPECIALIST_PARTIALLY_PAID: 'Provider Partially Paid',
  IME_SPECIALIST_FULLY_PAID: 'Provider Fully Paid',
}

export const IME_GROUP_TASK = {
  pic1: 'REQUEST_RECEIVED',
  pic2: 'APPOINTMENT',
  pic3: 'PAPERWORK',
  pic4Attendance: 'ATTENDANCE',
  pic4Cancellation: 'CANCELLATION',
  REPORT_WRITING: 'REPORT_WRITING',
  FURTHER_REVIEW: 'FURTHER_REVIEW',
  POST_ADMIN: 'POST_ADMIN',
  QUALITY_ASSURANCE: 'QUALITY_ASSURANCE',
  REPORT_FINALISATION: 'REPORT_FINALISATION',
  pic5: 'DICTATION',
  pic6: 'REPORT_DRAFT',
  pic7: 'REPORT_CORECTED',
  pic8: 'REPORT_FINAL',
  pic9: 'OT_QUOTATION',
}

export const SUPP_STATUS = {
  SUPP_CASE_CREATED: 'SUPP_CASE_CREATED',
  SUPP_REPORT_REQUESTED: 'SUPP_REPORT_REQUESTED',
  SUPP_PAPERWORK_RECEIVED: 'SUPP_PAPERWORK_RECEIVED',
  SUPP_DICTATION_RECEIVED: 'SUPP_DICTATION_RECEIVED',
  SUPP_REPORT_DRAFT_RECEIVED: 'SUPP_REPORT_DRAFT_RECEIVED',
  SUPP_REPORT_RETURNED: 'SUPP_REPORT_RETURNED',
  SUPP_REPORT_COMPLETED: 'SUPP_REPORT_COMPLETED',
  SUPP_CLARIFICATION_NO_FEE: 'SUPP_CLARIFICATION_NO_FEE',
  SUPP_CANCELLED_NO_FEE: 'SUPP_CANCELLED_NO_FEE',
  SUPP_CANCELLED_WITH_FEE: 'SUPP_CANCELLED_WITH_FEE',
  SUPP_CLIENT_INVOICED: 'SUPP_CLIENT_INVOICED',
  SUPP_CLIENT_PARTIALLY_PAID: 'SUPP_CLIENT_PARTIALLY_PAID',
  SUPP_CLIENT_FULLY_PAID: 'SUPP_CLIENT_FULLY_PAID',
  SUPP_SPECIALIST_INVOICED: 'SUPP_SPECIALIST_INVOICED',
  SUPP_SPECIALIST_PARTIALLY_PAID: 'SUPP_SPECIALIST_PARTIALLY_PAID',
  SUPP_SPECIALIST_FULLY_PAID: 'SUPP_SPECIALIST_FULLY_PAID',
}

export const SUPP_STATUS_LABEL = {
  SUPP_CASE_CREATED: 'Case Created',
  SUPP_REPORT_REQUESTED: 'Request Received',
  SUPP_PAPERWORK_RECEIVED: 'Request Sent to Specialist',
  SUPP_DICTATION_RECEIVED: 'Dictation Received from Specialist',
  SUPP_REPORT_DRAFT_RECEIVED: 'Report Draft Sent to Specialist',
  SUPP_REPORT_RETURNED: 'Report Received from Specialist',
  SUPP_REPORT_COMPLETED: 'Report Sent to CMs (Chargeable)',
  SUPP_CLARIFICATION_WITH_FEE: 'Report Sent to CMs (With Fee)',
  SUPP_CLARIFICATION_NO_FEE: 'Report Sent to CMs (No Fee)',
  SUPP_CANCELLED_NO_FEE: 'Cancelled (No Fee)',
  SUPP_CANCELLED_WITH_FEE: 'Cancelled (Chargeable)',
  SUPP_CLIENT_INVOICED: 'Customer Invoice Processed',
  SUPP_CLIENT_PARTIALLY_PAID: 'Customer Invoice Partially Paid',
  SUPP_CLIENT_FULLY_PAID: 'Customer Fee Fully Paid',
  SUPP_SPECIALIST_INVOICED: 'Provider Payment Processed',
  SUPP_SPECIALIST_PARTIALLY_PAID: 'Provider Partially Paid',
  SUPP_SPECIALIST_FULLY_PAID: 'Provider Fully Paid',
}
export const FR_STATUS_LABEL = {
  ...SUPP_STATUS_LABEL,
  SUPP_REPORT_COMPLETED: 'Report Sent to CMs (Chargeable)',
}
export const SUPP_GROUP_TASK = {
  pic1: 'REQUEST_RECEIVED',
  pic2: 'PAPERWORK',
  pic5: 'DICTATION',
  pic6: 'REPORT_DRAFT',
  pic7: 'REPORT_CORECTED',
  pic8: 'REPORT_FINAL',
  pic9: 'CANCELLATION',
}

export const MVA_GROUP_TASK = {
  claim_detail: 'MVA_CLAIM_DETAIL',
  insurer_response: 'INSURER_RESPONSE',
  settlement_authority: 'SETTLEMENT_AUTHORITY',
  mva_lor: 'MVA_LOR',
  treatment_plan: 'MVA_TREATMENT_PLAN',
  demand_letter: 'DEMAND_LETTER',
  termination: 'TERMINATION',
}

export const ATTACHMENT_TYPE = {
  GENERAL: 'GENERAL',
  AMENDED_REPORT_COMPLETED: 'AMENDED_REPORT_COMPLETED',
  AMENDED_REPORT_COMPLETED_ORIGIN_CASE: 'AMENDED_REPORT_COMPLETED_ORIGIN_CASE',
  CLIENT_PAYMENT_RECEIVED: 'CLIENT_PAYMENT_RECEIVED',
  COMPLETED_REPORT: 'COMPLETED_REPORT',
  COMPLETED_REPORT_ORIGIN_CASE: 'COMPLETED_REPORT_ORIGIN_CASE',
  CONSENT_FORM: 'CONSENT_FORM',
  DICTATION_REPORT: 'DICTATION_REPORT',
  DICTATION_INSTRUCTION: 'DICTATION_INSTRUCTION',
  SPECIALIST_REPORT_TEMPLATE: 'SPECIALIST_REPORT_TEMPLATE',
  SPECIALIST_SAMPLE: 'SPECIALIST_SAMPLE',
  DRAFT_REPORT: 'DRAFT_REPORT',
  CLIENT_VIDEO_CHECKLIST: 'CLIENT_VIDEO_CHECKLIST',
  SPECIALIST_VIDEO_CHECKLIST: 'SPECIALIST_VIDEO_CHECKLIST',
  CLIENT_INVOICE: 'CLIENT_INVOICE',
  PAPERWORK: 'PAPERWORK',
  PAPERWORK_ORIGIN_CASE: 'PAPERWORK_ORIGIN_CASE',
  MERGE_PAPERWORK: 'MERGE_PAPERWORK',
  PAPERWORK_OTHER: 'PAPERWORK_OTHER',
  PAPERWORK_OTHER_ORIGIN_CASE: 'PAPERWORK_OTHER_ORIGIN_CASE',
  MERGE_PAPERWORK_OTHER: 'MERGE_PAPERWORK_OTHER',
  MERGE_PAPERWORK_OTHER_ORIGIN_CASE: 'MERGE_PAPERWORK_OTHER_ORIGIN_CASE',
  SPECIALIST_INVOICE: 'SPECIALIST_INVOICE',
  SPECIALIST_PAYMENT_RECEIVED: 'SPECIALIST_PAYMENT_RECEIVED',
  REPORT_RETURNED: 'REPORT_RETURNED',
  SPECIALIST_REPORT_NOTE: 'SPECIALIST_REPORT_NOTE',
  TYPIST_REPORT: 'TYPIST_REPORT',
  WIRO_FUNDING_APPROVAL: 'WIRO_FUNDING_APPROVAL',
  APPOINTMENT_CONFIRMATION_CLIENT: 'APPOINTMENT_CONFIRMATION_CLIENT',
  APPOINTMENT_CONFIRMATION_CM: 'APPOINTMENT_CONFIRMATION_CM',
  APPOINTMENT_CONFIRMATION_SUB_CM: 'APPOINTMENT_CONFIRMATION_SUB_CM',
  APPOINTMENT_CONFIRMATION_SPECIALIST: 'APPOINTMENT_CONFIRMATION_SPECIALIST',
  NDIS_REFERRAL: 'NDIS_REFERRAL',
  INSURANCE_CLAIM: 'INSURANCE_CLAIM',
  INSURANCE_CLAIM_MDL_FILING: 'INSURANCE_CLAIM_MDL_FILING',
  INSURANCE_CLAIM_TOLLING_AGREEMENT: 'INSURANCE_CLAIM_TOLLING_AGREEMENT',
  INSURANCE_CLAIM_EXPENSE_INVOICES: 'INSURANCE_CLAIM_EXPENSE_INVOICES',
  CR_RECEIVED: 'CR_RECEIVED',
  MERGE_CR_RECEIVED: 'MERGE_CR_RECEIVED',
  FUNDING_TRANSACTION: 'FUNDING_TRANSACTION',
  DISBURSEMENT_ADVANCE_RECEIPT: 'DISBURSEMENT_ADVANCE_RECEIPT',
  DISBURSEMENT_REMITTANCE_ADVICE: 'DISBURSEMENT_REMITTANCE_ADVICE',
  DISBURSEMENT_SERVICE_PROVIDER_INVOICE: 'DISBURSEMENT_SERVICE_PROVIDER_INVOICE',
  CORRESPONDENCE: 'CORRESPONDENCE',
  RISK_ANALYSIS: 'RISK_ANALYSIS',
  NDIS_SESSION_CN: 'NDIS_SESSION_CN',
  NDIS_SERVICE_AGREEMENT: 'NDIS_SERVICE_AGREEMENT',
  NDIS_SERVICE_QUOTE: 'NDIS_SERVICE_QUOTE',
  SPECIALIST_ACCREDITATION: 'SPECIALIST_ACCREDITATION',
}

export const MR_GROUP_TASK = {
  pic1: 'REQUEST_RECEIVED',
  pic2: 'PAPERWORK',
  pic3: 'REPORT_FINAL',
  pic4onHold: 'ONHOLD',
  pic4Cancellation: 'CANCELLATION',
  pic5: 'PRE_PAYMENT',
}

export const MR_STATUS = {
  CR_CASE_CREATED: 'CR_CASE_CREATED',
  CR_REPORT_REQUESTED: 'CR_REPORT_REQUESTED',
  CR_PAPERWORK_RECEIVED: 'CR_PAPERWORK_RECEIVED',
  CR_CANCELLED_NO_FEE: 'CR_CANCELLED_NO_FEE',
  CR_CANCELLED_WITH_FEE: 'CR_CANCELLED_WITH_FEE',
  CR_ONHOLD: 'CR_ONHOLD',
  CR_FILES_SENT: 'CR_FILES_SENT',
  CR_CLIENT_INVOICED: 'CR_CLIENT_INVOICED',
  CR_CLIENT_PARTIALLY_PAID: 'CR_CLIENT_PARTIALLY_PAID',
  CR_CLIENT_FULLY_PAID: 'CR_CLIENT_FULLY_PAID',
  CR_SPECIALIST_INVOICED: 'CR_SPECIALIST_INVOICED',
  CR_SPECIALIST_PARTIALLY_PAID: 'CR_SPECIALIST_PARTIALLY_PAID',
  CR_SPECIALIST_FULLY_PAID: 'CR_SPECIALIST_FULLY_PAID',
}

export const MR_STATUS_LABEL = {
  CR_CASE_CREATED: 'Case Created',
  CR_REPORT_REQUESTED: 'Request Received',
  CR_PAPERWORK_RECEIVED: 'Request Sent to Provider',
  CR_FILES_SENT: 'MR Files Sent to CMs',
  CR_CANCELLED_NO_FEE: 'Cancelled (No Fee)',
  CR_CANCELLED_WITH_FEE: 'Cancelled (Chargeable)',
  CR_ONHOLD: 'On Hold',
  CR_CLIENT_INVOICED: 'Customer Invoice Processed',
  CR_CLIENT_PARTIALLY_PAID: 'Customer Invoice Partially Paid',
  CR_CLIENT_FULLY_PAID: 'Customer Invoice Fully Paid',
  CR_SPECIALIST_INVOICED: 'Provider Payment Processed',
  CR_SPECIALIST_PARTIALLY_PAID: 'Provider Partially Paid',
  CR_SPECIALIST_FULLY_PAID: 'Provider Fully Paid',
}

export const MN_STATUS = {
  MN_CASE_CREATED: 'MN_CASE_CREATED',
  MN_REPORT_REQUESTED: 'MN_REPORT_REQUESTED',
  MN_PAPERWORK_RECEIVED: 'MN_PAPERWORK_RECEIVED',
  MN_CANCELLED_WITH_BOOKING: 'MN_CANCELLED_WITH_BOOKING',
  MN_CANCELLED_NO_BOOKING: 'MN_CANCELLED_NO_BOOKING',
  MN_ON_HOLD: 'MN_ON_HOLD',
}

export const MN_STATUS_LABEL = {
  MN_CASE_CREATED: 'Created',
  MN_REPORT_REQUESTED: 'Open',
  MN_PAPERWORK_RECEIVED: 'Request Sent to Specialists',
  MN_CANCELLED_WITH_BOOKING: 'Close With Bookings',
  MN_CANCELLED_NO_BOOKING: 'Close Without Bookings',
  MN_ON_HOLD: 'On Hold',
}

export const MN_CASE_PROGRESS = [
  // {
  //   key: MN_STATUS.MN_CASE_CREATED,
  //   active: false,
  // },
  {
    key: MN_STATUS.MN_REPORT_REQUESTED,
    active: false,
  },
  {
    key: MN_STATUS.MN_PAPERWORK_RECEIVED,
    active: false,
  },
  {
    key: MN_STATUS.MN_CANCELLED_WITH_BOOKING,
    active: false,
  },
  {
    key: MN_STATUS.MN_CANCELLED_NO_BOOKING,
    active: false,
  },
  {
    key: MN_STATUS.MN_ON_HOLD,
    active: false,
  },
]

export const MN_GROUP_TASK = {
  pic1: 'REQUEST_RECEIVED',
  pic2: 'PAPERWORK',
  pic3: 'EXTENDED_OPINION',
  pic4: 'CANCELLATION',
}

export enum BILLING_STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  COMPLETED = 'COMPLETED',
}

export const BILLING_STATUS_OPTION = [
  { id: BILLING_STATUS_TYPE.ACTIVE, displayName: 'Active' },
  { id: BILLING_STATUS_TYPE.COMPLETED, displayName: 'Closed' },
]

export enum LOOKUP_CONFIG_TYPE {
  CENTRE_SERVICE_TYPE = 'CENTRE_SERVICE_TYPE',
  CHANNEL_CAMPAIGN = 'CHANNEL_CAMPAIGN',
  PAPER_TO_DOCTOR = 'PAPER_TO_DOCTOR',
  TARGET_AUDIENCE = 'TARGET_AUDIENCE',
  TARGET_STATUS_CAMPAIGN = 'TARGET_STATUS_CAMPAIGN',
  // MAIL_RECEIVER_TYPE = 'MAIL_RECEIVER_TYPE',
  SPECIALTY = 'SPECIALTY',
  SUB_SPECIALTY = 'SUB_SPECIALTY',
  QUALIFICATION = 'QUALIFICATION',
  ACCREDITATION = 'ACCREDITATION',
  TYPE_OF_CLAIM = 'TYPE_OF_CLAIM',
  TYPE_OF_REPORT = 'TYPE_OF_REPORT',
  SUB_TYPE_OF_REPORT = 'SUB_TYPE_OF_REPORT',
  QASTATUS = 'QASTATUS',
  GREETING_TITLE = 'GREETING_TITLE',
  JOB_TITLE = 'JOB_TITLE',
  MAIL_MODULE_TYPE = 'MAIL_MODULE_TYPE',
  INDUSTRY_EXPERT_TYPE = 'INDUSTRY_EXPERT_TYPE',
  PRIMARY_SEGMENT = 'PRIMARY_SEGMENT',
  SECONDARY_SEGMENT = 'SECONDARY_SEGMENT',
  SERVICE_GROUP = 'SERVICE_GROUP',
  DOCTOR_INDUSTRY_EXPERT = 'DOCTOR_INDUSTRY_EXPERT',
  TYPE_OF_WORK = 'TYPE_OF_WORK',
  BODY_PART = 'BODY_PART',
  LANGUAGES = 'LANGUAGES',
  SOURCE_REFERRAL = 'SOURCE_REFERRAL',
  SERVICE_TYPE = 'SERVICE_TYPE',
  CLIENT_PROFILE_PMS = 'CLIENT_PROFILE_PMS',
  CLIENT_PROFILE_CMS = 'CLIENT_PROFILE_CMS',
  SALE_TYPE = 'SALE_TYPE',
  REGISTER_GROUP = 'REGISTER_GROUP',
  SUPPORT_CATEGORY = 'SUPPORT_CATEGORY',
  REASON_REFERRAL = 'REASON_REFERRAL',
  ICARE_REPORT_CAPACITY = 'ICARE_REPORT_CAPACITY',
  ICARE_REPORT_DIAGNOSIS = 'ICARE_REPORT_DIAGNOSIS',
  ICARE_REPORT_LIABILITY = 'ICARE_REPORT_LIABILITY',
  ICARE_REPORT_TREATMENT = 'ICARE_REPORT_TREATMENT',
  ICARE_REPORT_WPI = 'ICARE_REPORT_WPI',
  ICARE_REPORT_PSYWPI = 'ICARE_REPORT_PSYWPI',
  ICARE_REPORT_IMC = 'ICARE_REPORT_IMC',
  ICARE_SERVICE_TYPE = 'ICARE_SERVICE_TYPE',
  RELATIONSHIP_ACTIVITY = 'RELATIONSHIP_ACTIVITY',
  CASE_NOTE = 'CASE_NOTE',
  EXPERTISE_AREAS_OF_PRACTICE = 'EXPERTISE_AREAS_OF_PRACTICE',
  TEMPLATE_ACTION_REQUIRE = 'TEMPLATE_ACTION_REQUIRE',
  PERSON_TYPE = 'PERSON_TYPE',
  PRACTICE_TYPE = 'PRACTICE_TYPE',
  RESTRAINT_SUB_TYPE = 'RESTRAINT_SUB_TYPE',
}

export enum FROM_MODULE_TYPE {
  SPECIALIST = 'SPECIALIST', // Doctor
  COMPANY = 'COMPANY',
  CENTRE = 'CENTRE', // Clinic
  CASE_MANAGER = 'CASE_MANAGER',
  CLIENT = 'CLIENT', // Claimant, PatientInfo
  CONTRACTOR = 'CONTRACTOR', // Typist
  IME = 'IME', // Assessment
  MT = 'MT', // Mass Torts',
  MEDICAL_TREATMENT_PROVIDER = 'MEDICAL_TREATMENT_PROVIDER',
  SUPP = 'SUPP', // Supplementary
  FR = 'FR', // File Review
  FR_TELECONFERENCE = 'FR_TELECONFERENCE',
  MR = 'MR', // Clinical Note, Medical Record
  MN = 'MN', // Medical Negligence
  MN_TELECONFERENCE = 'MN_TELECONFERENCE',
  MRI = 'MRI',
  INVOICE = 'INVOICE',
  CAMPAIGN = 'CAMPAIGN',
  FUNDING_AGREEMENT = 'FUNDING_AGREEMENT',
  FUNDING_TRANSACTION = 'FUNDING_TRANSACTION',
  DISBURSEMENT_FUNDING = 'DISBURSEMENT_FUNDING',
  DISBURSEMENT_FUNDING_SETTLEMENT = 'DISBURSEMENT_FUNDING_SETTLEMENT',
  REPAYMENT_INVOICE = 'REPAYMENT_INVOICE',
  TRANSACTION_PAYMENT = 'TRANSACTION_PAYMENT',
  FUNDING_REQUEST = 'FUNDING_REQUEST',
  CHECKLIST_CRITERIA = 'CHECKLIST_CRITERIA',
  NDIS = 'NDIS',
  NDIS_APPOINTMENT = 'NDIS_APPOINTMENT',
  STAFF = 'STAFF',
  INSURANCE_CLAIM = 'INSURANCE_CLAIM',
  SYSTEM_UPDATE = 'SYSTEM_UPDATE',
  SYSTEM_INVOICE = 'SYSTEM_INVOICE',
  SPECIALTY_MN = 'SPECIALTY_MN',
  SPECIALIST_MN = 'SPECIALIST_MN',
  MN_PO_REQUEST = 'MN_PO_REQUEST',
  MN_EO_REQUEST = 'MN_EO_REQUEST',
  OT = 'OT',
  AGREEMENT_ADVANCE_REQUEST = 'AGREEMENT_ADVANCE_REQUEST',
  TIME_TRACKING = 'TIME_TRACKING',
  MVA = 'MVA',
  MVA_MEDICAL_PROVIDER = 'MVA_MEDICAL_PROVIDER',
  MVA_INSURER = 'MVA_INSURER',
  OT_QUOTATION = 'OT_QUOTATION',
  OTHER = 'OTHER',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  CORRESPONDENCE = 'CORRESPONDENCE',
  FUNDING_PROVIDER = 'FUNDING_PROVIDER',
  PROVIDER_REMITTANCE = 'PROVIDER_REMITTANCE',
}

export enum THIRD_PARTY_SOURCE {
  CLIO = 'CLIO',
  PMS = 'PMS',
  LEAP = 'LEAP',
  PE = 'PRACTICE_EVOLVE',
  AS = 'ACTION_STEP',
  SMOKE = 'SMOKE_BALL',
  UHG = 'UHG',
}

export const THIRD_PARTY_SOURCE_LIST = [
  {
    id: THIRD_PARTY_SOURCE.CLIO,
    displayName: 'Clio',
  },
  {
    id: THIRD_PARTY_SOURCE.LEAP,
    displayName: 'LEAP',
  },
  {
    id: THIRD_PARTY_SOURCE.PE,
    displayName: 'Practice Evolve',
  },
  {
    id: THIRD_PARTY_SOURCE.AS,
    displayName: 'Action Step',
  },
  {
    id: THIRD_PARTY_SOURCE.SMOKE,
    displayName: 'Smoke',
  },
]
export const ERROR_MESSAGES = {
  required: 'This field is required',
  email: 'Invalid email address',
  url: 'Please enter a valid url starts with http:// | https://',
  max: {
    string: 'Must be less than or equal to :max digits',
  },
  min: {
    string: 'Must be more than or equal to :min digits',
  },
  required_without: {
    string: 'Must select at least one recruitment status',
  },
}

export const EMAIL_TYPE = {
  //CM
  TEMPLATE_27A: 'TEMPLATE_27A',
  //
  VIDEO_LINK_TO_INTERPRETER: 'VIDEO_LINK_TO_INTERPRETER',
  VIDEO_LINK_TO_CLIENT: 'VIDEO_LINK_TO_CLIENT',
  VIDEO_LINK_TO_SPECIALIST: 'VIDEO_LINK_TO_SPECIALIST',
  EMAIL_INTERPRETATION_TO_OPERATOR: 'EMAIL_INTERPRETATION_TO_OPERATOR',
  INTERPRETATION_EMAIL: 'INTERPRETATION_EMAIL',
  APPOINTMENT_INQUIRY_TO_STAFF_PIC: 'APPOINTMENT_INQUIRY_TO_STAFF_PIC',
  APPOINTMENT_INQUIRY_TO_CM: 'APPOINTMENT_INQUIRY_TO_CM',
  APPOINTMENT_INQUIRY_TO__SPECIALIST: 'APPOINTMENT_INQUIRY_TO__SPECIALIST',
  APPOINTMENT_CONFIRMATION_TO_STAFF_PIC: 'APPOINTMENT_CONFIRMATION_TO_STAFF_PIC',
  APPOINTMENT_CONFIRMATION_TO_CLIENT: 'APPOINTMENT_CONFIRMATION_TO_CLIENT',
  APPOINTMENT_CONFIRMATION_TO_CM: 'APPOINTMENT_CONFIRMATION_TO_CM',
  APPOINTMENT_CONFIRMATION_TO_SUB_CM: 'APPOINTMENT_CONFIRMATION_TO_SUB_CM',
  APPOINTMENT_CONFIRMATION_TO_SPECIALIST: 'APPOINTMENT_CONFIRMATION_TO_SPECIALIST',
  APPOINTMENT_CONFIRMATION_TO_SURROGATE_PHYSIOTHERAPIST: 'APPOINTMENT_CONFIRMATION_TO_SURROGATE_PHYSIOTHERAPIST',
  APPOINTMENT_RESCHEDULE_TO_CM: 'APPOINTMENT_RESCHEDULE_TO_CM',
  APPOINTMENT_RESCHEDULE_TO_SPECIALIST: 'APPOINTMENT_RESCHEDULE_TO_SPECIALIST',
  APPOINTMENT_RESCHEDULE_TO_SURROGATE_SPECIALIST: 'APPOINTMENT_RESCHEDULE_TO_SURROGATE_SPECIALIST',
  APPOINTMENT_RESCHEDULE_TO_STAFF_PIC: 'APPOINTMENT_RESCHEDULE_TO_STAFF_PIC',
  APPOINTMENT_RESCHEDULE_TO_CLIENT: 'APPOINTMENT_RESCHEDULE_TO_CLIENT',
  APPOINTMENT_RESCHEDULE_TO_INTERPRETER: 'APPOINTMENT_RESCHEDULE_TO_INTERPRETER',
  DICTATION_FILES: 'DICTATION_FILES',
  DICTATION_FILES_TO_STAFF: 'DICTATION_FILES_TO_STAFF',
  REPORT_DRAFT_TO_PERSONAL_EMAIL_SPECIALIST: 'REPORT_DRAFT_TO_PERSONAL_EMAIL_SPECIALIST',
  REPORT_DRAFT_TO_BUSINESS_EMAIL_SPECIALIST: 'REPORT_DRAFT_TO_BUSINESS_EMAIL_SPECIALIST',
  PAPERWORK_TO_SPECIALIST: 'PAPERWORK_TO_SPECIALIST',
  PAPERWORK_REMINDER_TO_CM: 'PAPERWORK_REMINDER_TO_CM',
  PAPERWORK_READINESS_TO_STAFF: 'PAPERWORK_READINESS_TO_STAFF',
  REPORT_COMPLETED: 'REPORT_COMPLETED',
  REPORT_COMPLETED_TO_SOLICITOR: 'REPORT_COMPLETED_TO_SOLICITOR',
  REPORT_COMPLETED_TO_SPECIALIST: 'REPORT_COMPLETED_TO_SPECIALIST',
  AMENDED_REPORT_COMPLETED: 'AMENDED_REPORT_COMPLETED',
  AMENDED_REPORT_COMPLETED_TO_SOLICITOR: 'AMENDED_REPORT_COMPLETED_TO_SOLICITOR',
  PAYMENT_RECEIPT_TO_CLIENT: 'PAYMENT_RECEIPT_TO_CLIENT',
  PAYMENT_RECEIPT_TO_SPECIALIST: 'PAYMENT_RECEIPT_TO_SPECIALIST',
  INVOICE: 'INVOICE',
  CORRECTED_REPORT_TO_STAFF: 'CORRECTED_REPORT_TO_STAFF',
  CORRECTED_REPORT_TO_SPECIALIST: 'CORRECTED_REPORT_TO_SPECIALIST',
  INTERPRETATION_PROVIDER_EMAIL: 'INTERPRETATION_PROVIDER_EMAIL',
  TYPIST_FILES: 'TYPIST_FILES',
  APPOINTMENT_CONFIRMATION_TO_CM_SOLICITOR: 'APPOINTMENT_CONFIRMATION_TO_CM_SOLICITOR',
  APPOINTMENT_RESCHEDULE_TO_CM_SOLICITOR: 'APPOINTMENT_RESCHEDULE_TO_CM_SOLICITOR',
  OT_QUOTATION_TO_STAFF_PIC: 'OT_QUOTATION_TO_STAFF_PIC',
  OT_QUOTATION_TO_CM: 'OT_QUOTATION_TO_CM',
  OT_QUOTATION_CANCELLATION_TO_STAFF: 'OT_QUOTATION_CANCELLATION_TO_STAFF',
  OT_QUOTATION_CANCELLATION_TO_CM: 'OT_QUOTATION_CANCELLATION_TO_CM',
  BOOKING_CONFIRMATION_TO_CLIENT: 'BOOKING_CONFIRMATION_TO_CLIENT',
  BOOKING_CONFIRMATION_TO_CM: 'BOOKING_CONFIRMATION_TO_CM',
  BOOKING_CONFIRMATION_TO_STAFF: 'BOOKING_CONFIRMATION_TO_STAFF',
  ICARE_MATTER_TO_STAFF: 'ICARE_MATTER_TO_STAFF',
  ICARE_MATTER_TO_SPECIALIST: 'ICARE_MATTER_TO_SPECIALIST',
  APPOINTMENT_REQUESTED_TO_STAFF_PIC: 'APPOINTMENT_REQUESTED_TO_STAFF_PIC',
  // CR MR
  COMPLETION_UPDATE_CM: 'COMPLETION_UPDATE_CM',
  LOA_TEMPLATE_TO_CM: 'LOA_TEMPLATE_TO_CM',
  LOA_TO_SPECIALIST: 'LOA_TO_SPECIALIST',
  CANCELLITION_TO_CM: 'CANCELLITION_TO_CM',
  CANCELLITION_TO_CM_SOLICITOR: 'CANCELLITION_TO_CM_SOLICITOR',
  CANCELLITION_TO_SPECIALIST: 'CANCELLITION_TO_SPECIALIST',
  CR_FILES_SENT: 'CR_FILES_SENT',
  CR_FILES_SENT_SOLICITOR: 'CR_FILES_SENT_SOLICITOR',
  ON_HOLD_CM: 'ON_HOLD_CM',
  ON_HOLD_CM_SOLICITOR: 'ON_HOLD_CM_SOLICITOR',
  ON_HOLD_SPECIALIST: 'ON_HOLD_SPECIALIST',
  SEND_INVOICE_TO_CLIENT: 'SEND_INVOICE_TO_CLIENT',
  SEND_INVOICE_TO_OPERATOR: 'SEND_INVOICE_TO_OPERATOR',
  SEND_RECEIVED_TO_SPECIALIST: 'SEND_RECEIVED_TO_SPECIALIST',
  PAYMENT_RECEIPT_TO_STAFF: 'PAYMENT_RECEIPT_TO_STAFF',

  // MN
  PO_REQUEST_SPECIALIST: 'PO_REQUEST_SPECIALIST',
  EO_RATE: 'EO_RATE',
  PO_RATE: 'PO_RATE',
  PO_REQUEST_CM: 'PO_REQUEST_CM',
  EO_REQUEST_RATE: 'EO_REQUEST_RATE',
  PO_REQUEST_RATE: 'PO_REQUEST_RATE',
  PO_RESULT: 'PO_RESULT',
  EO_DOCUMENT: 'EO_DOCUMENT',
  EO_REQUEST_CM: 'EO_REQUEST_CM',
  VIDEO_LINK_SPECIALIST: 'VIDEO_LINK_SPECIALIST',
  VIDEO_LINK_CM: 'VIDEO_LINK_CM',
  SPECIALIST_QUOTE: 'SPECIALIST_QUOTE',
  CM_QUOTE: 'CM_QUOTE',
  CM_CANCELLATION: 'CM_CANCELLATION',
  SPECIALIST_CANCELLATION: 'SPECIALIST_CANCELLATION',

  CANCELLATION_NOTICE_TO_SOLICITOR: 'CANCELLATION_NOTICE_TO_SOLICITOR',
  CANCELLATION_NOTICE_TO_CM: 'CANCELLATION_NOTICE_TO_CM',
  CANCELLATION_NOTICE_TO_SPECIALIST: 'CANCELLATION_NOTICE_TO_SPECIALIST',
  CANCELLATION_NOTICE_TO_STAFF_PIC_CASE: 'CANCELLATION_NOTICE_TO_STAFF_PIC_CASE',
  CANCELLATION_NOTICE_TO_SPECIALIST_AM: 'CANCELLATION_NOTICE_TO_SPECIALIST_AM',
  CANCELLATION_NOTICE_TO_SURROGATE_PHYSIOTHERAPIST: 'CANCELLATION_NOTICE_TO_SURROGATE_PHYSIOTHERAPIST',
  CANCELLATION_NOTICE_TO_CLIENT: 'CANCELLATION_NOTICE_TO_CLIENT',
  CANCELLATION_NOTICE_TO_STAFF_PIC: 'CANCELLATION_NOTICE_TO_STAFF_PIC',
  CANCELLATION_NOTICE_TO_INTERPRETER: 'CANCELLATION_NOTICE_TO_INTERPRETER',
  CANCELLATION_NOTICE_TO_RESCHEDULING_REMINDERS: 'CANCELLATION_NOTICE_TO_RESCHEDULING_REMINDERS',
  CANCELLATION_NOTICE_TO_RESCHEDULING_REMINDERS_TO_CM: 'CANCELLATION_NOTICE_TO_RESCHEDULING_REMINDERS_TO_CM',

  // NDIS
  APPOINTMENT_REQUEST_CLIENT: 'APPOINTMENT_REQUEST_CLIENT',
  APPOINTMENT_REQUEST_CM: 'APPOINTMENT_REQUEST_CM',
  APPOINTMENT_REQUEST_SPECIALIST: 'APPOINTMENT_REQUEST_SPECIALIST',
  APPOINTMENT_REQUEST_KIN: 'APPOINTMENT_REQUEST_KIN',
  APPOINTMENT_REQUEST_REPRE: 'APPOINTMENT_REQUEST_REPRE',

  APPOINTMENT_CONFIRM_CLIENT: 'APPOINTMENT_CONFIRM_CLIENT',
  APPOINTMENT_CONFIRM_CM: 'APPOINTMENT_CONFIRM_CM',
  APPOINTMENT_CONFIRM_SPECIALIST: 'APPOINTMENT_CONFIRM_SPECIALIST',
  APPOINTMENT_CONFIRM_KIN: 'APPOINTMENT_CONFIRM_KIN',
  APPOINTMENT_CONFIRM_REPRE: 'APPOINTMENT_CONFIRM_REPRE',
  APPOINTMENT_CONFIRM_ATTENDEE: 'APPOINTMENT_CONFIRM_ATTENDEE',

  APPOINTMENT_CANCELLATION_CLIENT: 'APPOINTMENT_CANCELLATION_CLIENT',
  APPOINTMENT_CANCELLATION_CM: 'APPOINTMENT_CANCELLATION_CM',
  APPOINTMENT_CANCELLATION_SPECIALIST: 'APPOINTMENT_CANCELLATION_SPECIALIST',
  APPOINTMENT_CANCELLATION_KIN: 'APPOINTMENT_CANCELLATION_KIN',
  APPOINTMENT_CANCELLATION_REPRE: 'APPOINTMENT_CANCELLATION_REPRE',
  APPOINTMENT_CANCELLATION_ATTENDEE: 'APPOINTMENT_CANCELLATION_ATTENDEE',

  FINAL_REPORT_CLIENT: 'FINAL_REPORT_CLIENT',
  FINAL_REPORT_CM: 'FINAL_REPORT_CM',
  FINAL_REPORT_KIN: 'FINAL_REPORT_KIN',
  FINAL_REPORT_REPRE: 'FINAL_REPORT_REPRE',
  SERVICE_AGREEMENT_TO_CLIENT: 'SERVICE_AGREEMENT_TO_CLIENT',
  SERVICE_AGREEMENT_TO_CM: 'SERVICE_AGREEMENT_TO_CM',
  //MVA
  LIENT_REQUEST_TO_CENTRE: 'LIENT_REQUEST_TO_CENTRE',
  LOR_TO_INSURER: 'LOR_TO_INSURER',
  LOR_TO_BODY_INJURY_CM: 'LOR_TO_BODY_INJURY_CM',
  LOR_TO_UNINSURED_MOTORIST_BODY_INJURY_CM: 'LOR_TO_UNINSURED_MOTORIST_BODY_INJURY_CM',
  LOR_TO_PROPERTY_DAMAGE_CM: 'LOR_TO_PROPERTY_DAMAGE_CM',
  LOA_TO_LC: 'LOA_TO_LC',
  AUTHORIZATION_LETTER_TO_CENTRE: 'AUTHORIZATION_LETTER_TO_CENTRE',
  GUARANTEE_LETTER_TO_CENTRE: 'GUARANTEE_LETTER_TO_CENTRE',
  DEMAND_LETTER_TO_INSURER: 'DEMAND_LETTER_TO_INSURER',
  DEMAND_LETTER_TO_BODY_INJURY_CM: 'DEMAND_LETTER_TO_BODY_INJURY_CM',
  DEMAND_LETTER_TO_UNINSURED_MOTORIST_BODY_INJURY_CM: 'DEMAND_LETTER_TO_UNINSURED_MOTORIST_BODY_INJURY_CM',
  DEMAND_LETTER_TO_PROPERTY_DAMAGE_CM: 'DEMAND_LETTER_TO_PROPERTY_DAMAGE_CM',
  SETTLEMENT_AUTHORITY_LETTER_TO_CLIENT: 'SETTLEMENT_AUTHORITY_LETTER_TO_CLIENT',
  SIGNED_CLIENT_AUTHORITY_TO_INSURER: 'SIGNED_CLIENT_AUTHORITY_TO_INSURER',
  SIGNED_CLIENT_AUTHORITY_TO_BODY_INJURY_CM: 'SIGNED_CLIENT_AUTHORITY_TO_BODY_INJURY_CM',
  SIGNED_CLIENT_AUTHORITY_TO_UNINSURED_MOTORIST_BODY_INJURY_CM:
    'SIGNED_CLIENT_AUTHORITY_TO_UNINSURED_MOTORIST_BODY_INJURY_CM',
  OFFER_NOTICE_TO_CLIENT: 'OFFER_NOTICE_TO_CLIENT',
  OFFER_NOTICE_TO_LAWFIRM: 'OFFER_NOTICE_TO_LAWFIRM',
  OFFER_NOTICE_TO_INSURER: 'OFFER_NOTICE_TO_INSURER',
  FEE_AGREEMENT_T0_CLIENT: 'FEE_AGREEMENT_T0_CLIENT',
  INSURER_RESPONSE_NOTICE_TO_CLIENT: 'INSURER_RESPONSE_NOTICE_TO_CLIENT',
  INSURER_RESPONSE_NOTICE_TO_LAWFIRM: 'INSURER_RESPONSE_NOTICE_TO_LAWFIRM',
  FINAL_DISBURSEMENT_TO_CLIENT: 'FINAL_DISBURSEMENT_TO_CLIENT',
  TERMINATION_TO_CLIENT: 'TERMINATION_TO_CLIENT',

  //Disbursements
  CLAIM_TO_INSURER: 'CLAIM_TO_INSURER',
  CLAIM_TO_FUNDING_PROVIDER: 'CLAIM_TO_FUNDING_PROVIDER',
  REPAYMENT_INVOICE_TO_CUSTOMER: 'REPAYMENT_INVOICE_TO_CUSTOMER',
  REPAYMENT_INVOICE_TO_CASE_MANAGER: 'REPAYMENT_INVOICE_TO_CASE_MANAGER',
  ATTENDANCE_STATUS_REMINDER_TO_SPECIALIST: 'ATTENDANCE_STATUS_REMINDER_TO_SPECIALIST',
  DISBURSEMENT_FUNDING_CANCELLED_TO_LENDER: 'DISBURSEMENT_FUNDING_CANCELLED_TO_LENDER',
  DISBURSEMENT_FUNDING_CANCELLED_TO_CASE_MANAGER: 'DISBURSEMENT_FUNDING_CANCELLED_TO_CASE_MANAGER',
}

export const REASON_CANCELLATION = {
  Unsure: 'Unsure',
  AtTheRequest: 'AtTheRequest',
  SpecialistBeingUnable: 'SpecialistBeingUnable',
  NewAppointmentDetails: 'NewAppointmentDetails',
  LateConfirm: 'LateConfirm',
  Other: 'Other',
  CaseManagerRequest: 'CaseManagerRequest',
}
export enum CORRESPONDENCE_TYPE_ENUM {
  PHONE = 'PHONE',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  OPERATOR_EMAIL = 'OPERATOR_EMAIL',
  PRIVATE_NOTE = 'PRIVATE_NOTE',
  UNSUCCESSFUL_PHONE_CALL = 'UNSUCCESSFUL_PHONE_CALL',
  UNSUCCESSFUL_FAX = 'UNSUCCESSFUL_FAX',
  RELATIONSHIP_MEETING = 'RELATIONSHIP_MEETING',
  RELATIONSHIP_CALL = 'RELATIONSHIP_CALL',
  RELATIONSHIP_EMAIL = 'RELATIONSHIP_EMAIL',
  OTHERS = 'OTHERS',
  RELATIONSHIP_MESSAGE = 'RELATIONSHIP_MESSAGE',
}

export const RELATIONSHIP_CORRESPONDENCE_TYPE = [
  {
    id: CORRESPONDENCE_TYPE_ENUM.RELATIONSHIP_MEETING,
    displayName: 'Relationship Meeting',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.RELATIONSHIP_CALL,
    displayName: 'Relationship Call',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.RELATIONSHIP_EMAIL,
    displayName: 'Relationship Email',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.RELATIONSHIP_MESSAGE,
    displayName: 'Relationship Message',
  },
]
export const OTHER_RELATIONSHIP_CORRESPONDENCE_TYPE = [
  {
    id: CORRESPONDENCE_TYPE_ENUM.OTHERS,
    displayName: 'Others',
  },
]
export const CORRESPONDENCE_TYPE = [
  {
    id: CORRESPONDENCE_TYPE_ENUM.PHONE,
    displayName: 'Phone',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.SMS,
    displayName: 'SMS',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.EMAIL,
    displayName: 'Email',
  },
  // {
  //   id: CORRESPONDENCE_TYPE_ENUM.OPERATOR_EMAIL,
  //   displayName: 'MLP Email',
  // },
  {
    id: CORRESPONDENCE_TYPE_ENUM.PRIVATE_NOTE,
    displayName: 'Private Notes',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.UNSUCCESSFUL_PHONE_CALL,
    displayName: 'Unsuccessful Phone Call',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.UNSUCCESSFUL_FAX,
    displayName: 'Unsuccessful Fax',
  },
  ...RELATIONSHIP_CORRESPONDENCE_TYPE,
  ...OTHER_RELATIONSHIP_CORRESPONDENCE_TYPE,
]
export const CM_AND_SPECIALIST_CORRESPONDENCE_TYPE = [
  ...RELATIONSHIP_CORRESPONDENCE_TYPE,
  ...OTHER_RELATIONSHIP_CORRESPONDENCE_TYPE,

  {
    id: CORRESPONDENCE_TYPE_ENUM.PHONE,
    displayName: 'Phone',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.SMS,
    displayName: 'SMS',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.EMAIL,
    displayName: 'Email',
  },
  // {
  //   id: CORRESPONDENCE_TYPE_ENUM.OPERATOR_EMAIL,
  //   displayName: 'MLP Email',
  // },
  {
    id: CORRESPONDENCE_TYPE_ENUM.PRIVATE_NOTE,
    displayName: 'Private Notes',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.UNSUCCESSFUL_PHONE_CALL,
    displayName: 'Unsuccessful Phone Call',
  },
  {
    id: CORRESPONDENCE_TYPE_ENUM.UNSUCCESSFUL_FAX,
    displayName: 'Unsuccessful Fax',
  },
]
export const CORRESPONDENCE_CONTENT_CATEGORY = [
  {
    id: 'CORRESPONDENCE_CASE_CREATED',
    displayName: 'Case Booking',
  },
  { id: 'CORRESPONDENCE_PAPERWORK', displayName: 'Paperwork' },
  { id: 'CORRESPONDENCE_APPOINTMENT_CONFIRMED', displayName: 'Appointment Confirmation & Reschedule' },
  { id: 'CORRESPONDENCE_APPOINTMENT_ATTENDED', displayName: 'Appointment Attendance' },
  { id: 'CORRESPONDENCE_APPOINTMENT_NOT_ATTENDED', displayName: 'Appointment Non-Attendance' },
  { id: 'CORRESPONDENCE_CANCELLED', displayName: 'Case Cancellation' },
  { id: 'CORRESPONDENCE_DICTATION_RECEIVED', displayName: 'Dictation' },
  { id: 'CORRESPONDENCE_REPORT_WRITING', displayName: 'Collect Report from Specialist' },
  { id: 'CORRESPONDENCE_BILLING', displayName: 'Billing' },
]

export const PAPERWORK_SEND_METHODS = [
  { id: 'COURIER', displayName: 'Courier' },
  { id: 'EXPRESS_POST', displayName: 'Express Post' },
  { id: 'INHOUSE', displayName: 'In House' },
  { id: 'STANDARD_POST', displayName: 'Standard Post' },
  { id: 'FAX', displayName: 'Fax' },
]

var zones = moment.tz.names()

export const TIMEZONE_LIST = zones.map(zoneName => {
  return {
    id: zoneName,
    displayName: `(${moment.tz(zoneName).format('Z')}) ${zoneName}`,
    gmt: `${moment.tz(zoneName).format('Z')}`,
  }
})

export const SIDEBAR_WIDTH = {
  SMALL: '5rem', // window.innerWidth <= 500 ? '0px' : '5rem',
  LARGE: 'var(--sidebar-width)',
}

export const dataSelectAll = [{ displayName: 'All', id: null }]

export const TYPE_ACCOUNT_INFO = {
  CLIENT: 'CLIENT',
  CASE_MANAGER: 'CASE_MANAGER',
  CONTRACTOR: 'CONTRACTOR',
  SPECIALIST: 'SPECIALIST',
  CENTRE: 'CENTRE',
  COMPANY: 'COMPANY',
}

export const MODULE_LABEL = {
  STAFF: 'Staff',
  SPECIALIST: 'Specialist', // Doctor
  COMPANY: 'Company',
  CENTRE: 'Centre', // Clinic
  CASE_MANAGER: 'Case Manager',
  CLIENT: 'Client', // Claimant, PatientInfo
  CONTRACTOR: 'Contractor', // Typist
  IME: 'IME Assessment', // Assessment
  OT: 'OT',
  SUPP: 'Supplementary', // Supplementary
  FR: 'File Review', // File Review
  MR: 'Medical Record', // Medical Record
  MN: 'Medical Negligence', // Medical Negligence
  MRI: 'MRI',
  FI: 'Factual Investigation', // Factual Investigation
  INVOICE: 'Invoice',
  CAMPAIGN: 'Campaign',
  NDIS: 'NDIS',
}

export const RECEIVER_SENDERS = [
  {
    key: 'CASE_MANAGER',
    title: 'Case Manager',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'SPECIALIST',
    title: 'Specialist',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'CLIENT',
    title: translate('Client'),
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: false,
    isReceiver: true,
  },
  {
    key: 'CONTRACTOR',
    title: 'Contractor',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'STAFF',
    title: 'Staff',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'LENDER',
    title: 'Lender',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'BORROWER',
    title: 'Borrower',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'OTHER',
    title: 'Other',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: false,
    isReceiver: true,
  },
]

export const CALLER_SENDERS = [
  {
    key: 'STAFF',
    title: 'Staff',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'CASE_MANAGER',
    title: 'Case Manager',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'SPECIALIST',
    title: 'Specialist',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'CLIENT',
    title: translate('Client'),
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: false,
  },
  {
    key: 'CONTRACTOR',
    title: 'Contractor',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'LENDER',
    title: 'Lender',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
  {
    key: 'BORROWER',
    title: 'Borrower',
    disabled: false,
    defaultId: null,
    disabledSelectType: false,
    isCaller: true,
    isReceiver: true,
  },
]

export const FUNDING_REQUEST_STATUS = {
  PENDING: 1,
  SENT: 2,
  UNDER_REVIEW: 3,
  APPROVED: 4,
  REJECTED: 5,
  NONE: null,
}

export const FUNDING_REQUEST_STATUS_LABEL = {
  1: 'Pending',
  2: 'Sent',
  3: 'UnderReview',
  4: 'Approved',
  5: 'Rejected',
}

export const FUNDING_REQUEST_STATUS_DISPLAY_NAME: any = {
  Pending: 'Pending',
  Sent: 'Sent',
  UnderReview: 'Under Review',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Certified: 'Certified',
}

export const NDIS_CASE_STATUS = {
  NDIS_CASE_CREATED: 'NDIS_CASE_CREATED',
  NDIS_REPORT_REQUESTED: 'NDIS_REPORT_REQUESTED',
  NDIS_CLIENT_INVOICED: 'NDIS_CLIENT_INVOICED',
  NDIS_SPECIALIST_INVOICED: 'NDIS_SPECIALIST_INVOICED',
  NDIS_CLIENT_PARTIALLY_PAID: 'NDIS_CLIENT_PARTIALLY_PAID',
  NDIS_SPECIALIST_PARTIALLY_PAID: 'NDIS_SPECIALIST_PARTIALLY_PAID',
  NDIS_CLIENT_FULLY_PAID: 'NDIS_CLIENT_FULLY_PAID',
  NDIS_SPECIALIST_FULLY_PAID: 'NDIS_SPECIALIST_FULLY_PAID',
}

export const NDIS_APPOINTMENT_STATUS_ENUM = {
  NDIS_APPOINTMENT_REQUESTED: 1,
  NDIS_APPOINTMENT_CONFIRMED: 2,
  NDIS_ATTENDED: 3,
  NDIS_NOT_ATTEND: 4,
  NDIS_REPORT_COMPLETED: 5,
  NDIS_CANCELLED_NO_FEE: 6,
  NDIS_CANCELLED_WITH_FEE: 7,
}

export const NDIS_CASE_STATUS_LABEL = {
  NDIS_CASE_CREATED: 'Case Create',
  NDIS_REPORT_REQUESTED: 'Report Requested',
  NDIS_CLIENT_INVOICED: 'Service Fee Invoiced',
  NDIS_SPECIALIST_INVOICED: 'Provider Payment Processed',
  NDIS_CLIENT_PARTIALLY_PAID: 'Service Fee Partially Paid',
  NDIS_SPECIALIST_PARTIALLY_PAID: 'Provider Partially Paid',
  NDIS_CLIENT_FULLY_PAID: 'Service Fee Fully Paid',
  NDIS_SPECIALIST_FULLY_PAID: 'Provider Fully Paid',
}

export const NDIS_CASE_PROGRESS = [
  {
    key: NDIS_CASE_STATUS.NDIS_CASE_CREATED,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_REPORT_REQUESTED,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_CLIENT_INVOICED,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_SPECIALIST_INVOICED,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_CLIENT_PARTIALLY_PAID,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_SPECIALIST_PARTIALLY_PAID,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_CLIENT_FULLY_PAID,
    active: false,
  },
  {
    key: NDIS_CASE_STATUS.NDIS_SPECIALIST_FULLY_PAID,
    active: false,
  },
]

export const SESSION_ACCEPT_TYPE = {
  ALL: 'ALL',
  INPERSON: 'INPERSON',
  VIDEO: 'VIDEO',
  BOTH: 'BOTH',
  NDIS: 'NDIS',
  PHONE: 'PHONE',
  OT: 'OT',
}

export const SESSION_ACCEPT_TYPE_LABEL = [
  { displayName: `All`, id: SESSION_ACCEPT_TYPE.ALL },
  { displayName: `In-person`, id: SESSION_ACCEPT_TYPE.INPERSON },
  { displayName: `Telehealth`, id: SESSION_ACCEPT_TYPE.VIDEO },
  { displayName: `In-person/Telehealth`, id: SESSION_ACCEPT_TYPE.BOTH },
  { displayName: `NDIS`, id: SESSION_ACCEPT_TYPE.NDIS },
  { displayName: `Phone`, id: SESSION_ACCEPT_TYPE.PHONE },
  { displayName: `OT`, id: SESSION_ACCEPT_TYPE.OT },
]

export const NDIS_APPOINTMENT_STATUS = {
  NDIS_APPOINTMENT_REQUESTED: 'NDIS_APPOINTMENT_REQUESTED',
  NDIS_APPOINTMENT_CONFIRMED: 'NDIS_APPOINTMENT_CONFIRMED',
  NDIS_ATTENDED: 'NDIS_ATTENDED',
  NDIS_NOT_ATTEND: 'NDIS_NOT_ATTEND',
  NDIS_REPORT_COMPLETED: 'NDIS_REPORT_COMPLETED',
  NDIS_CANCELLED_NO_FEE: 'NDIS_CANCELLED_NO_FEE',
  NDIS_CANCELLED_WITH_FEE: 'NDIS_CANCELLED_WITH_FEE',
  NDIS_REPORT_REQUESTED: 'NDIS_REPORT_REQUESTED',
}
export const CENTRE_OPTION = {
  OPERATOR: 'OPERATOR',
  SPECIALIST: 'SPECIALIST',
  OBTAIN: 'OBTAIN',
  HOME: 'HOME',
}
export enum IME_SERVICE_TYPE {
  IME = 0,
  MT = 1,
  OT = 2,
}

export enum OT_PROCESS_STATUS {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export const OT_PROCESS_STATUS_OPTION = [
  {
    id: OT_PROCESS_STATUS.INCOMPLETE,
    displayName: 'Incomplete',
  },
  {
    id: OT_PROCESS_STATUS.COMPLETE,
    displayName: 'Complete',
  },
  {
    id: OT_PROCESS_STATUS.NOT_REQUIRED,
    displayName: 'Not Required',
  },
]

export enum URGENCY_PAPERWORK_ENUM {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  RETRIEVED = 'RETRIEVED',
}
export const OPTION_URGENCY_PAPERWORK = [
  {
    id: URGENCY_PAPERWORK_ENUM.LOW,
    displayName: 'Low',
  },
  {
    id: URGENCY_PAPERWORK_ENUM.MEDIUM,
    displayName: 'Medium',
  },
  {
    id: URGENCY_PAPERWORK_ENUM.HIGH,
    displayName: 'High/Overdue',
  },
  {
    id: URGENCY_PAPERWORK_ENUM.RETRIEVED,
    displayName: 'Retrieved',
  },
]

export enum SUPP_SERVICE_TYPE {
  SUPP = 0,
  FR = 1,
}

export enum ROLE_NAME {
  TENANT_ADMIN = 'ADMIN',
  ADMIN_STAFF = 'ADMIN_STAFF',
  COMPANY = 'COMPANY',
  CASE_MANAGER = 'CASE_MANAGER',
  SPECIALIST = 'SPECIALIST',
  CONTRACTOR = 'CONTRACTOR',
  STAFF = 'STAFF',
  ACCOUNTING = 'ACCOUNTING',
  INSURER = 'INSURER',
}

export enum APP_FEATURES {
  Assessment = 'Application:Assessment',
  Disbursement = 'Application:Disbursement',
  FundingTransaction = 'Application:FundingTransaction',
  DisbursementFunding = 'Application:DisbursementFunding',
  InsuranceClaim = 'Application:InsuranceClaim',
  LMQ = 'Application:LMQ',
  FileReview = 'Application:FileReview',
  MVA = 'Application:MVA',
  MarketingCampaign = 'Application:MarketingCampaign',
  MassTorts = 'Application:MassTorts',
  MedNeg = 'Application:MedNeg',
  MedicalRecord = 'Application:MedicalRecord',
  NDIS = 'Application:NDIS',
  OT = 'Application:OT',
  PowerBIReport = 'Application:PowerBIReport',
  Subscription = 'Application:Subscription',
  Supplementary = 'Application:Supplementary',
  SystemUpdate = 'Application:SystemUpdate',
  LegalPracticeAPI = 'Application:LegalPracticeAPI',
  TimeTracking = 'Application:TimeTracking',
  TransactionInvoice = 'Application:TransactionInvoice',
}

function GetModulePermission(moduleName: string) {
  const prefix = 'ICS.' + moduleName
  return {
    Default: prefix,
    Edit: prefix + '.Edit',
    Create: prefix + '.Create',
    Delete: prefix + '.Delete',
  }
}
export const APP_PERMISSIONS = {
  User: GetModulePermission('User'),
  Client: GetModulePermission('Client'),
  Specialist: GetModulePermission('Specialist'),
  LookupConfig: GetModulePermission('LookupConfig'),
  Centre: GetModulePermission('Centre'),
  Company: GetModulePermission('Company'),
  CaseManger: GetModulePermission('CaseManger'),
  SaleTarget: GetModulePermission('SaleTarget'),
  ServiceItem: GetModulePermission('ServiceItem'),
  ClinicalRecord: GetModulePermission('ClinicalRecord'),
  Assessment: GetModulePermission('Assessment'),
  MedNeg: GetModulePermission('MedNeg'),
  NDIS: GetModulePermission('NDIS'),
  Supplementary: GetModulePermission('Supplementary'),
  MVA: GetModulePermission('MVA'),
  Correspondence: GetModulePermission('Correspondence'),
  CaseStatusLog: GetModulePermission('CaseStatusLog'),
  ActionRequired: GetModulePermission('ActionRequired'),
  Calendar: GetModulePermission('Calendar'),
  Contractor: GetModulePermission('Contractor'),
  VideoSession: GetModulePermission('VideoSession'),
  EmailTemplate: GetModulePermission('EmailTemplate'),
  Subscriptions: GetModulePermission('Subscriptions'),
  Disbursement: {
    FacilityRequest: GetModulePermission('Disbursement.FacilityRequest'),
    FacilityAgreement: GetModulePermission('Disbursement.FacilityAgreement'),
    FacilityTransaction: GetModulePermission('Disbursement.FacilityTransaction'),
  },
}

export const RoleOptions = [
  { displayName: `Staff Admin`, id: `ADMIN_STAFF` },
  { displayName: `Company`, id: `COMPANY` },
  { displayName: `Case Manager`, id: `CASE_MANAGER` },
  { displayName: `Client`, id: `CLIENT` },
  { displayName: `Specialist`, id: `SPECIALIST` },
  { displayName: `Contractor`, id: `CONTRACTOR` },
  { displayName: `Staff`, id: `STAFF` },
]

export enum INSURANCE_CLAIM_STATUS {
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export const INSURANCE_CLAIM_STATUS_OPTIONS = [
  {
    id: 0,
    statusName: 'PENDING',
    displayName: 'Pending',
  },
  { id: 1, statusName: 'PROCESSED', displayName: 'Submitted' },
  { id: 2, statusName: 'APPROVED', displayName: 'Approved' },
  { id: 3, statusName: 'REJECTED', displayName: 'Rejected' },
  { id: 4, statusName: 'CANCELLED', displayName: 'Cancelled' },
]

export const INSURANCE_CLAIM_PAYMENT_STATUS = [
  {
    id: 0,
    displayName: 'Fully Paid',
  },
  { id: 1, displayName: 'Partial Pay' },
]

export const INSURANCE_CLAIM_CASE_STATUS = [
  {
    id: 0,
    displayName: 'MDL and lost without prospect of being filed in a State Court',
  },
  { id: 1, displayName: 'Did not enter MDL and can be pursued in State Court' },
  { id: 2, displayName: 'Filed in State Courts' },
]

export const INSURANCE_CLAIM_CASE_STATUS_ENUM = {
  MDL_ENTERED: 0,
  MDL_DID_NOT_ENTER: 1,
  STATE_COURTS_FILED: 2,
}

export const FUNDING_TRANSACTION_ENUM = {
  PENDING: 0,
  IN_PROGRESS: 1,
  PARTIAL_PAY: 2,
  CLAIMED: 3,
  SETTLED: 4,
  CANCELLED: 5,
  FULL_PAY: 6,
}
export const FUNDING_TRANSACTION_STATUS = [
  {
    id: FUNDING_TRANSACTION_ENUM.PENDING,
    statusName: 'PENDING',
    displayName: 'Pending',
    disabled: false,
  },
  {
    id: FUNDING_TRANSACTION_ENUM.IN_PROGRESS,
    statusName: 'IN_PROGRESS',
    displayName: 'Requested',
    disabled: false,
  },
  { id: FUNDING_TRANSACTION_ENUM.PARTIAL_PAY, statusName: 'PARTIAL_PAY', displayName: 'Partial Pay', disabled: false },
  { id: FUNDING_TRANSACTION_ENUM.FULL_PAY, statusName: 'FULL_PAY', displayName: 'Full Pay', disabled: false },
  { id: FUNDING_TRANSACTION_ENUM.CANCELLED, statusName: 'CANCELLED', displayName: 'Cancelled', disabled: false },
]

export const CASE_STATUS_ENUM = {
  ACTIVE: 0,
  CANCELLED: 1,
}

export const CASE_STATUS_OPTION = [
  { id: CASE_STATUS_ENUM.ACTIVE, displayName: 'Active' },
  { id: CASE_STATUS_ENUM.CANCELLED, displayName: 'Cancelled' },
]

export const REPAYMENT_STATUS_ENUM = {
  Sent: 0,
  //CreditNote: 1,
  PartPay: 2,
  FullPay: 3,
  //Issued: 4,
  Pending: 5,
  Cancelled: 6,
}

export const CREDIT_NOTE_TYPE_ENUM = {
  REPAYMENT_INVOICE: 'RepaymentInvoice',
  INVOICE: 'Invoice',
  BILLING: 'Billing',
}
export const REPAYMENT_STATUS = [
  { id: REPAYMENT_STATUS_ENUM.Pending, statusName: 'Unpaid', displayName: 'Unpaid' },
  // { id: REPAYMENT_STATUS_ENUM.Sent, statusName: 'Sent', displayName: 'Sent' },
  // { id: REPAYMENT_STATUS_ENUM.CreditNote, statusName: 'CreditNote', displayName: 'Credit Note' },
  { id: REPAYMENT_STATUS_ENUM.PartPay, statusName: 'PartPay', displayName: 'Partial Pay' },
  { id: REPAYMENT_STATUS_ENUM.FullPay, statusName: 'FullPay', displayName: 'Full Pay' },
  { id: REPAYMENT_STATUS_ENUM.Cancelled, statusName: 'Cancelled', displayName: 'Cancelled' },
]

export const REPAYMENT_REVENUE_TYPE = [
  { id: 0, displayName: 'Original' },
  { id: 1, displayName: 'Extension' },
]
export enum PAYMENT_RECORD_TYPE_ENUM {
  REPAYMENT = 0,
  ADVANCE_PAYMENT = 1,
  SYSTEM_INVOICE = 2,
  REPAYMENT_CREDIT_NOTE = 3,
}
export const PAYMENT_RECORD_TYPE_OPTIONS = [
  { id: PAYMENT_RECORD_TYPE_ENUM.REPAYMENT, displayName: 'REPAYMENT' },
  { id: PAYMENT_RECORD_TYPE_ENUM.ADVANCE_PAYMENT, displayName: 'ADVANCE_PAYMENT' },
  { id: PAYMENT_RECORD_TYPE_ENUM.SYSTEM_INVOICE, displayName: 'SYSTEM_INVOICE' },
]

export enum PAYMENT_TYPE_ENUM {
  REVENUE = 0,
  CREDIT_NOTE = 1,
}
export const PAYMENT_TYPE_OPTIONS = [
  { id: PAYMENT_TYPE_ENUM.REVENUE, displayName: 'Revenue' },
  { id: PAYMENT_TYPE_ENUM.CREDIT_NOTE, displayName: 'Credit Note' },
]

export const ADVANCE_REQUEST_STATUS = [
  {
    id: 0,
    displayName: 'Standard',
  },
  { id: 1, displayName: 'Case Termination' },
]

export const TRANSCRIPT_AUDIO_FILE_TYPE = ['mp3', 'dss', 'm4a', 'aac', 'wav']

export const AUDIO_FILE_TYPE = ['mp3', 'dss', 'm4a', 'aac', 'wav', 'ds2']

export const UPLOAD_IMAGE_FILE_TYPE = ['jpg', 'jpeg', 'png', 'ico', 'svg', 'gif', 'bmp', 'tif', 'tiff']
export const DEFAULT_FILE_TYPE = [
  ...UPLOAD_IMAGE_FILE_TYPE,
  ...AUDIO_FILE_TYPE,
  'pdf',
  'docx',
  'doc',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
  'zip',
  'rar',
  'rtf',
  'xml',
  'htm',
  'html',
  'mp4',
]

export const EMAIL_DETAIL_TEMPLATE_TAB = {
  Information: '1',
  Content: '2',
}

export const EVENTBUS_TYPE = {
  FACILITY_AGREEMENT_CREATED: 'FACILITY_AGREEMENT_CREATED',
}

export const FormStepNames = {
  BookingNotes: 'BookingNotes',
  BookingCM: 'BookingCM',
  ClientDetails: 'ClientDetails',
  Appointment: 'Appointment',
  Paperwork: 'Paperwork',
  Cancellation: 'Cancellation',
  Attendance: 'Attendance',
  Dictations: 'Dictations',
  Payment: 'Payment',
  CaseCorrespondence: 'CaseCorrespondence',
  ActionRequired: 'ActionRequired',
  Video: 'Video',
  CreditRequest: 'CreditRequest',
}

export const CLASS_NAME_HEADER_CUSTOM_TABLE = 'top-0 sticky bg-white align-bottom py-3 px-6 text-left whitespace-nowrap'
export const CLASS_NAME_BODY_CUSTOM_TABLE = 'py-3 px-6 border-b border-grey-100 max-w-xs cut-text text-left'

export const TypeOfAssessmentsAcceptedEnum = {
  None: 0,
  Medicolegal: 1,
  NDIS: 2,
  Both: 3,
}
export const getNotifyResend = type => {
  switch (type) {
    case 'videoLinkInterpreter':
    case 'videoLinkClient':
    case 'videoLinkSpecialist':
      return 'Resend Kawaconn Video Link'
    case 'mailInterpreterToOperator':
    case 'mailInterpretation':
      return 'Resend Interpreter request'
    case 'notifyInquiryCM':
    case 'notifyInquirySpecialist':
      return 'Resend appointment inquiry'
    case 'appointmentConfirmationClient':
    case 'appointmentConfirmationCM':
    case 'appointmentConfirmationSpecialist':
      return 'Resend appointment confirmation'
    case 'dictationFilesToTypist':
      return 'Resend dictation files'
    case 'reportDraftToPESpecialist': //PE - Personal Email
    case 'reportDraftToBESpecialist': //BE - Business Email
      return 'Resend report draft'
    case 'paperwork':
      return 'Resend paperwork'
    case 'reportCompleteSpecialist':
    case 'reportCompleteCM':
    case 'reportCompleteSolicitor':
      return 'Resend report'
    case 'amendedReportCM':
    case 'amendedReportSolicitor':
      return 'Resend amended report'
    case 'dictationFiles': //done
    case 'dictationFilesToStaff': //done
      return 'Resend Dictation Files'
    case 'paymentReceiptToClient':
    case EMAIL_TYPE.SEND_INVOICE_TO_CLIENT:
      return 'Resend payment receipt to company'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_SPECIALIST:
      return 'Resend Cancellation Notice to specialist'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_STAFF_PIC_CASE:
      return 'Resend Cancellation Notice to Staff PIC'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_SPECIALIST_AM:
      return 'Resend Cancellation Notice to Account Manager of Specialist'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_CM:
      return 'Resend Cancellation Notice to booking CM'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_SOLICITOR:
      return 'Resend Cancellation Notice to booking solicitor'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_INTERPRETER:
      return 'Resend Cancellation Notice to booking interpreter'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_SURROGATE_PHYSIOTHERAPIST:
      return 'Resend Cancellation Notice to Surrogate Specialist'
    case 'paymentReceiptToSpecialist':
    case EMAIL_TYPE.SEND_RECEIVED_TO_SPECIALIST:
      return 'Resend payment receipt to specialist'
    case EMAIL_TYPE.PAYMENT_RECEIPT_TO_STAFF:
      return 'Resend payment receipt to staff'
    case 'invoice':
      return 'Resend invoice'
    case 'correctedReportToStaff':
      return 'Resend corrected report to staff'
    case 'correctedReportToSpecialist':
      return 'Resend corrected report to specialist'
    case 'mailInterpreterToProvider': //done
      return 'Resend Interpreter booking'
    case 'paperworkReadinessToStaff': //done
      return 'Resend Notify Paperwork Readiness To MLP'
    case 'sentDatePaperworkReceived': //done
      return 'Resend Paperwork to Specialist'
    //update constant for CR
    case 'mailLoaTemplateToCM':
      return 'Resend LoA template to booking CM'
    case 'mailLoaTemplateToClient':
      return 'Resend LoA template to Client'
    case 'mailLoaTemplateToSpecialist':
      return 'Resend LoA to MR Provider'
    case 'mailCancellitionToCM':
      return 'Resend cancellation to Booking CM'
    case 'mailCancellitionToSolicitor':
      return 'Resend cancellation to Booking Solicitor'
    case 'mailCancellitionToSpecialist':
      return 'Resend cancellation to MR Provider'
    case 'mailFileSent':
      return 'Resend Merged MR Files to Booking CM'
    case 'mailFileSentSolicitor':
      return 'Resend Merged MR Files to Solicitor'
    case 'mailOnHoldToCM':
      return 'Resend on hold to CMs'
    case 'mailOnHoldToCMSolicitor':
      return 'Resend on hold to Solicitors'
    case 'mailOnHoldToProvider':
      return 'Resend on hold to Provider'
    case 'mailCompletionUpdateToCMs':
      return 'Resend Completion Update to CMs'
    // MVA
    case 'lientRequestSentDate':
      return 'Resend Medical Provider'
    case 'isSendDemandLetterToInsurer':
      return `Resend Insurer`
    case 'isSendDemandLetterToBICaseManager':
      return `Resend Insurance Rep/Adjuster for BI`
    case 'isSendDemandLetterToUninsuredMotoristBodyInjuryCM':
      return `Resend Insurance Rep/Adjuster for Uninsured Motorist BI`
    case 'sendNoticeToClient':
      return `Resend ${translate('Client')}`
    case 'sendNoticeToLawFirm':
      return 'Resend Law Firm'
    case 'sendNoticeToInsurer':
      return `Resend Insurer`
    case 'sendSettlementAuthorityLetterToClient':
      return `Resend ${translate('Client')}`
    case 'sendSignedClientAuthorityToInsurer':
      return `Resend Insurer`
    case 'sendSignedClientAuthorityToBodyInjuryCM':
      return `Resend Insurance Rep/Adjuster for BI`
    case 'sendSignedClientAuthorityToUninsuredMotoristBodyInjuryCM':
      return `Resend Insurance Rep/Adjuster for Uninsured Motorist BI`
    case 'loR_IsSendToClient':
      return `Resend Insurer`
    case 'loR_IsSendToBodyInjuryCM':
      return `Resend Insurance Rep/Adjuster for BI`
    case 'loR_IsSendToUninsuredMotoristBodyInjuryCM':
      return `Resend Insurance Rep/Adjuster for Uninsured Motorist BI`
    case 'loR_IsSendToPropertyDamageCM':
      return `Resend Insurance Rep/Adjuster for Property Damage`
    case 'authorizationLetterToMedicalProviderSentDateTime':
      return 'Resend Medical Provider'
    case 'sendFeeAgreementToClient':
      return `Resend ${translate('Client')}`
    case 'insurerResponse_SendNoticeToClient':
      return `Resend ${translate('Client')}`
    case 'insurerResponse_SendNoticeToLawFirm':
      return 'Resend Law Firm'
    case 'isSendFinalDisbursementToClient':
      return 'Resend Final Disbursement'
    case 'sendTerminationLetterToClient':
      return 'Resend Termination Letter'
    case EMAIL_TYPE.CLAIM_TO_INSURER:
    case EMAIL_TYPE.CLAIM_TO_FUNDING_PROVIDER:
      return `Resend Claim`
    case EMAIL_TYPE.OT_QUOTATION_TO_STAFF_PIC:
      return 'Resend Quotation'
    case EMAIL_TYPE.OT_QUOTATION_TO_CM:
      return 'Resend Quotation'
    case EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_STAFF:
      return 'Resend Quotation Cancellation'
    case EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_CM:
      return 'Resend Quotation Cancellation'
    case EMAIL_TYPE.AUTHORIZATION_LETTER_TO_CENTRE:
      return 'Resend Letter of Authorization'
    case EMAIL_TYPE.GUARANTEE_LETTER_TO_CENTRE:
      return 'Resend Letter of Guarantee'
    case EMAIL_TYPE.SEND_INVOICE_TO_OPERATOR:
      return 'Resend MR Provider Invoice'
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CM:
      return 'Resend Booking Confirmation to CM'
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_STAFF:
      return 'Resend Booking Confirmation to Staff PIC'
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CLIENT:
      return 'Resend Booking Confirmation to Client'
    case EMAIL_TYPE.REPAYMENT_INVOICE_TO_CASE_MANAGER:
    case EMAIL_TYPE.REPAYMENT_INVOICE_TO_CUSTOMER:
      return `Resend Invoice`
    case EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_LENDER:
    case EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_CASE_MANAGER:
      return `Resend Cancelled Disbursement Funding`
    case EMAIL_TYPE.SERVICE_AGREEMENT_TO_CLIENT:
    case EMAIL_TYPE.SERVICE_AGREEMENT_TO_CM:
      return `Resend Service Agreement`
    case EMAIL_TYPE.ICARE_MATTER_TO_STAFF:
    case EMAIL_TYPE.ICARE_MATTER_TO_SPECIALIST:
      return `Sent iCare report reminder successfully!`
    case EMAIL_TYPE.ATTENDANCE_STATUS_REMINDER_TO_SPECIALIST:
      return `Attendance reminder was sent`
    default:
      return ''
  }
}
export const getTypeMail = (type = '') => {
  switch (type) {
    case 'videoLinkInterpreter': //done
      return EMAIL_TYPE.VIDEO_LINK_TO_INTERPRETER
    case 'videoLinkClient': //done
      return EMAIL_TYPE.VIDEO_LINK_TO_CLIENT
    case 'videoLinkSpecialist': //done
      return EMAIL_TYPE.VIDEO_LINK_TO_SPECIALIST
    case 'mailInterpreterToOperator': //done
      return EMAIL_TYPE.EMAIL_INTERPRETATION_TO_OPERATOR
    case 'mailInterpretation': //done
      return EMAIL_TYPE.INTERPRETATION_EMAIL
    case 'mailInterpreterToProvider': //done
      return EMAIL_TYPE.INTERPRETATION_PROVIDER_EMAIL
    case 'notifyInquiryCM': //done
      return EMAIL_TYPE.APPOINTMENT_INQUIRY_TO_CM
    case 'notifyInquirySpecialist': //done
      return EMAIL_TYPE.APPOINTMENT_INQUIRY_TO__SPECIALIST
    case 'appointmentConfirmationClient': //done
      return EMAIL_TYPE.APPOINTMENT_CONFIRMATION_TO_CLIENT
    case 'appointmentConfirmationCM': //done
      return EMAIL_TYPE.APPOINTMENT_CONFIRMATION_TO_CM
    case 'appointmentConfirmationBookingSolicitor': //done
      return EMAIL_TYPE.APPOINTMENT_CONFIRMATION_TO_CM_SOLICITOR
    case 'appointmentConfirmationSpecialist': //done
      return EMAIL_TYPE.APPOINTMENT_CONFIRMATION_TO_SPECIALIST
    case 'paperwork': //done
      return EMAIL_TYPE.PAPERWORK_TO_SPECIALIST
    case 'dictationFiles': //done
      return EMAIL_TYPE.DICTATION_FILES
    case 'dictationFilesToStaff': //done
      return EMAIL_TYPE.DICTATION_FILES_TO_STAFF
    case 'reportDraftToPESpecialist': //PE - Personal Email //done
      return EMAIL_TYPE.REPORT_DRAFT_TO_PERSONAL_EMAIL_SPECIALIST
    case 'reportDraftToBESpecialist': //BE - Business Email //done
      return EMAIL_TYPE.REPORT_DRAFT_TO_BUSINESS_EMAIL_SPECIALIST
    case 'reportCompleteSpecialist':
      return EMAIL_TYPE.REPORT_COMPLETED_TO_SPECIALIST
    case 'reportCompleteCM': //done
      return EMAIL_TYPE.REPORT_COMPLETED
    case 'reportCompleteSolicitor': //done
      return EMAIL_TYPE.REPORT_COMPLETED_TO_SOLICITOR
    case 'amendedReportCM': //done
      return EMAIL_TYPE.AMENDED_REPORT_COMPLETED
    case 'amendedReportSolicitor': //done
      return EMAIL_TYPE.AMENDED_REPORT_COMPLETED_TO_SOLICITOR
    case 'reminder':
      return EMAIL_TYPE.PAPERWORK_REMINDER_TO_CM
    case 'paymentReceiptToClient':
      return EMAIL_TYPE.PAYMENT_RECEIPT_TO_CLIENT
    case 'paymentReceiptToSpecialist':
      return EMAIL_TYPE.PAYMENT_RECEIPT_TO_SPECIALIST
    case 'invoice':
      return EMAIL_TYPE.INVOICE
    case 'correctedReportToStaff':
      return EMAIL_TYPE.CORRECTED_REPORT_TO_STAFF
    case 'correctedReportToSpecialist':
      return EMAIL_TYPE.CORRECTED_REPORT_TO_SPECIALIST
    case 'dictationFilesToTypist':
      return EMAIL_TYPE.TYPIST_FILES
    //update constant for CR
    case 'mailCompletionUpdateToCMs': //done
      return EMAIL_TYPE.COMPLETION_UPDATE_CM
    case 'mailLoaTemplateToCM': //done
    case 'mailLoaTemplateToClient':
      return EMAIL_TYPE.LOA_TEMPLATE_TO_CM
    case 'mailLoaTemplateToSpecialist': //done
      return EMAIL_TYPE.LOA_TO_SPECIALIST
    case 'mailCancellitionToCM': //done
      return EMAIL_TYPE.CANCELLITION_TO_CM
    case 'mailCancellitionToSolicitor': //done
      return EMAIL_TYPE.CANCELLITION_TO_CM_SOLICITOR
    case 'mailCancellitionToSpecialist': //done
      return EMAIL_TYPE.CANCELLITION_TO_SPECIALIST
    case 'mailFileSent': //done
      return EMAIL_TYPE.CR_FILES_SENT
    case 'mailFileSentSolicitor': //done
      return EMAIL_TYPE.CR_FILES_SENT_SOLICITOR
    case 'mailOnHoldToCM': //done
      return EMAIL_TYPE.ON_HOLD_CM
    case 'mailOnHoldToCMSolicitor': //done
      return EMAIL_TYPE.ON_HOLD_CM_SOLICITOR
    case 'mailOnHoldToProvider': //done
      return EMAIL_TYPE.ON_HOLD_SPECIALIST
    case 'paperworkReadinessToStaff':
      return EMAIL_TYPE.PAPERWORK_READINESS_TO_STAFF
    case EMAIL_TYPE.CLAIM_TO_INSURER:
      return EMAIL_TYPE.CLAIM_TO_INSURER
    case EMAIL_TYPE.CLAIM_TO_FUNDING_PROVIDER:
      return EMAIL_TYPE.CLAIM_TO_FUNDING_PROVIDER
    case EMAIL_TYPE.REPAYMENT_INVOICE_TO_CASE_MANAGER:
      return EMAIL_TYPE.REPAYMENT_INVOICE_TO_CASE_MANAGER
    case EMAIL_TYPE.REPAYMENT_INVOICE_TO_CUSTOMER:
      return EMAIL_TYPE.REPAYMENT_INVOICE_TO_CUSTOMER
    case EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_LENDER:
      return EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_LENDER
    case EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_CASE_MANAGER:
      return EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_CASE_MANAGER
    case EMAIL_TYPE.OT_QUOTATION_TO_STAFF_PIC:
      return EMAIL_TYPE.OT_QUOTATION_TO_STAFF_PIC
    case EMAIL_TYPE.OT_QUOTATION_TO_CM:
      return EMAIL_TYPE.OT_QUOTATION_TO_CM
    case EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_STAFF:
      return EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_STAFF
    case EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_CM:
      return EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_CM
    case EMAIL_TYPE.AUTHORIZATION_LETTER_TO_CENTRE:
      return EMAIL_TYPE.AUTHORIZATION_LETTER_TO_CENTRE
    case EMAIL_TYPE.GUARANTEE_LETTER_TO_CENTRE:
      return EMAIL_TYPE.GUARANTEE_LETTER_TO_CENTRE
    case EMAIL_TYPE.SEND_INVOICE_TO_OPERATOR:
      return EMAIL_TYPE.SEND_INVOICE_TO_OPERATOR
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CLIENT:
      return EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CLIENT
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CM:
      return EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CM
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_STAFF:
      return EMAIL_TYPE.BOOKING_CONFIRMATION_TO_STAFF
    case EMAIL_TYPE.SERVICE_AGREEMENT_TO_CLIENT:
      return EMAIL_TYPE.SERVICE_AGREEMENT_TO_CLIENT
    case EMAIL_TYPE.SERVICE_AGREEMENT_TO_CM:
      return EMAIL_TYPE.SERVICE_AGREEMENT_TO_CM
    case EMAIL_TYPE.ICARE_MATTER_TO_STAFF:
      return EMAIL_TYPE.ICARE_MATTER_TO_STAFF
    case EMAIL_TYPE.ICARE_MATTER_TO_SPECIALIST:
      return EMAIL_TYPE.ICARE_MATTER_TO_SPECIALIST
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_INTERPRETER:
      return EMAIL_TYPE.CANCELLATION_NOTICE_TO_INTERPRETER
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_SURROGATE_PHYSIOTHERAPIST:
      return EMAIL_TYPE.CANCELLATION_NOTICE_TO_SURROGATE_PHYSIOTHERAPIST
    default:
      return type
  }
}

export const getMessageConfirmResend = type => {
  switch (type) {
    case 'videoLinkInterpreter':
      return 'Do you want to resend Kawaconn Video Link to Interpreter?'
    case 'videoLinkClient':
      return 'Do you want to resend Kawaconn Video Link to Client?'
    case 'videoLinkSpecialist':
      return 'Do you want to resend Kawaconn Video Link to Specialist?'
    case 'mailInterpreterToOperator':
      return 'Do you want to send Interpreter request to MLP?'
    case 'mailInterpretation':
      return 'Do you want to resend appointment information to Interpreter?'
    case 'notifyInquiryCM':
      return 'Do you want to resend appointment inquiry to Case Manager?'
    case 'notifyInquirySpecialist':
      return 'Do you want to resend appointment inquiry to Specialist'
    case 'appointmentConfirmationClient':
      return 'Do you want to resend appointment confirmation to Client?'
    case 'appointmentConfirmationCM':
      return 'Do you want to resend appointment confirmation to Case Manager?'
    case 'appointmentConfirmationSpecialist':
      return 'Do you want to resend appointment confirmation to Specialist?'
    case 'dictationFilesToTypist':
      return 'Do you want to resend dictation files, report templates and previous reports to the typist?'
    case 'reportDraftToPESpecialist': //PE - Personal Email
      return 'Do you want to resend report draft to specialist?'
    case 'reportDraftToBESpecialist': //BE - Business Email
      return 'Do you want to resend report draft to specialist?'
    case 'paperwork':
      return 'Do you want to resend paperwork to Specialist?'
    case 'reportCompleteSpecialist':
      return 'Do you want to resend completed report to Specialist?'
    case 'reportCompleteCM':
      return 'Do you want to resend completed report to Report Receiving Contact 2?'
    case 'reportCompleteSolicitor':
      return 'Do you want to resend completed report to Report Receiving Contact 1?'
    case 'amendedReportCM':
      return 'Do you want to resend amended report to Report Receiving Contact 2?'
    case 'amendedReportSolicitor':
      return 'Do you want to resend amended report to Report Receiving Contact 1?'
    case 'dictationFiles': //done
      return 'Do you want to resend confirmation to specialist?'
    case 'dictationFilesToStaff':
      return 'Do you want to resend confirmation to staff?'
    case 'paymentReceiptToClient':
      return 'Do you want to resend payment receipt to company?'
    case 'paymentReceiptToSpecialist':
    case EMAIL_TYPE.SEND_RECEIVED_TO_SPECIALIST:
      return 'Do you want to resend payment receipt to specialist?'
    case EMAIL_TYPE.PAYMENT_RECEIPT_TO_STAFF:
      return 'Do you want to resend payment receipt to staff?'
    case 'invoice':
      return 'Do you want to resend invoice to the operator?'
    case 'correctedReportToStaff':
      return 'Do you want to resend corrected report to staff?'
    case 'correctedReportToSpecialist':
      return 'Do you want to resend corrected report to specialist?'
    case 'mailInterpreterToProvider': //done
      return 'Do you want to resend service request to Interpreter Provider?'
    //update constant for CR
    case 'mailCompletionUpdateToCMs':
      return 'Do you want to send Completion Update to CMs?'
    case 'mailLoaTemplateToCM':
      return 'Do you want to resend LoA template to booking CM?'
    case 'mailLoaTemplateToClient':
      return 'Do you want to resend LoA template to client?'
    case 'mailLoaTemplateToSpecialist':
      return 'Do you want to resend LoA to MR Provider?'
    case 'CANCELLATION_NOTICE_TO_CLIENT':
      return 'Do you want to resend cancellation to Client/Examinee?'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_STAFF_PIC:
      return 'Do you want to resend cancellation to Staff PIC 4?'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_INTERPRETER:
      return 'Do you want to resend cancellation to Interpreter?'
    case EMAIL_TYPE.CANCELLATION_NOTICE_TO_SURROGATE_PHYSIOTHERAPIST:
      return 'Do you want to resend cancellation to Surrogate Specialist?'
    case 'CANCELLATION_NOTICE_TO_CM':
    case 'mailCancellitionToCM':
      return 'Do you want to resend cancellation to CM?'
    case 'mailCancellitionToSolicitor':
    case 'CANCELLATION_NOTICE_TO_SOLICITOR':
      return 'Do you want to resend cancellation to Solicitor?'
    case 'CANCELLATION_NOTICE_TO_SPECIALIST':
    case 'mailCancellitionToSpecialist':
      return 'Do you want to resend cancellation to Specialist?'
    case 'CANCELLATION_NOTICE_TO_STAFF_PIC_CASE':
      return 'Do you want to resend cancellation to Staff PIC?'
    case 'CANCELLATION_NOTICE_TO_SPECIALIST_AM':
      return 'Do you want to resend cancellation to Account Manager of Specialist?'
    case 'CANCELLATION_NOTICE_TO_RESCHEDULING_REMINDERS':
      return 'Do you want to resend Rescheduling reminders to staff?'
    case 'CANCELLATION_NOTICE_TO_RESCHEDULING_REMINDERS_TO_CM':
      return 'Do you want to resend Rescheduling reminders to booking CM?'
    case 'mailFileSent':
      return 'Do you want to resend send MR Files to CMs?'
    case 'mailFileSentSolicitor':
      return 'Do you want to resend send MR Files to Solicitor?'
    case 'mailOnHoldToCM':
      return 'Do you want to resend on-hold to CMs?'
    case 'mailOnHoldToCMSolicitor':
      return 'Do you want to resend on-hold to Solicitors?'
    case 'mailOnHoldToProvider':
      return 'Do you want to resend on-hold to Provider?'
    case 'paperworkReadinessToStaff':
      return 'Do you want to resend paperwork readiness to Staff?'
    case 'LIENT_REQUEST_TO_CENTRE':
      return 'Do you want to resend lien request to Centre?'
    case 'LOR_TO_INSURER':
      return 'Do you want to resend LoR to Insurer?'
    case 'LOR_TO_BODY_INJURY_CM':
      return 'Do you want to resend LoR to BI?'
    case 'LOR_TO_UNINSURED_MOTORIST_BODY_INJURY_CM':
      return 'Do you want to resend LoR to Uninsured Motorist BI?'
    case 'LOR_TO_PROPERTY_DAMAGE_CM':
      return 'Do you want to resend LoR to Property Damage?'
    case 'LOA_TO_LC':
      return 'Do you want to resend LoA to Law firm?'
    case EMAIL_TYPE.AUTHORIZATION_LETTER_TO_CENTRE:
      return 'Do you want to resend Letter of Authorization to Medical Provider?'
    case EMAIL_TYPE.GUARANTEE_LETTER_TO_CENTRE:
      return 'Do you want to resend Letter Guarantee to Medical Provider?'
    case 'DEMAND_LETTER_TO_INSURER':
      return 'Do you want to resend Demand Letter to Insurer?'
    case 'DEMAND_LETTER_TO_BODY_INJURY_CM':
      return 'Do you want to resend Demand Letter to BI?'
    case 'DEMAND_LETTER_TO_UNINSURED_MOTORIST_BODY_INJURY_CM':
      return 'Do you want to resend Demand Letter to Uninsured Motorist BI?'
    case 'DEMAND_LETTER_TO_PROPERTY_DAMAGE_CM':
      return 'Do you want to resend Demand Letter to Property Damage?'
    case 'SETTLEMENT_AUTHORITY_LETTER_TO_CLIENT':
      return 'Do you want to resend Settlement Authority Letter to Client?'
    case 'SIGNED_CLIENT_AUTHORITY_TO_INSURER':
      return 'Do you want to resend Signed Client Authority to Insurer?'
    case 'SIGNED_CLIENT_AUTHORITY_TO_UNINSURED_MOTORIST_BODY_INJURY_CM':
      return 'Do you want to resend Signed Client Authority to Uninsured Motorist BI?'
    case 'SIGNED_CLIENT_AUTHORITY_TO_BODY_INJURY_CM':
      return 'Do you want to resend Signed Client Authority to BI?'
    case 'OFFER_NOTICE_TO_LAWFIRM':
      return 'Do you want to resend notice to Law Firm?'
    case 'OFFER_NOTICE_TO_CLIENT':
      return 'Do you want to resend notice to Client?'
    case 'OFFER_NOTICE_TO_INSURER':
      return `Do you want to resend notice to Insurer?`
    case 'FEE_AGREEMENT_T0_CLIENT':
      return `Do you want to resend notice to Client?`
    case 'INSURER_RESPONSE_NOTICE_TO_CLIENT':
      return `Do you want to resend notice to Client?`
    case 'INSURER_RESPONSE_NOTICE_TO_LAWFIRM':
      return `Do you want to resend notice to Law Firm?`
    case 'FINAL_DISBURSEMENT_TO_CLIENT':
      return `Do you want to resend Final Disbursement to Client`
    case 'TERMINATION_TO_CLIENT':
      return `Do you want to resend Termination Letter to Client`
    case EMAIL_TYPE.CLAIM_TO_INSURER:
      return `Do you want to resend Claim to Insurance Rep?`
    case EMAIL_TYPE.CLAIM_TO_FUNDING_PROVIDER:
      return `Do you want to resend Claim to Funding Provider?`
    case EMAIL_TYPE.REPAYMENT_INVOICE_TO_CASE_MANAGER:
      return `Do you want to resend invoice to Contact Person?`
    case EMAIL_TYPE.REPAYMENT_INVOICE_TO_CUSTOMER:
      return `Do you want to resend invoice to Paying Customer?`
    case EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_LENDER:
      return `Do you want to resend cancellation to Lender?`
    case EMAIL_TYPE.DISBURSEMENT_FUNDING_CANCELLED_TO_CASE_MANAGER:
      return `Do you want to resend cancellation to Case Manager?`
    case EMAIL_TYPE.OT_QUOTATION_TO_STAFF_PIC:
      return 'Do you want to send Quotation to Staff?'
    case EMAIL_TYPE.OT_QUOTATION_TO_CM:
      return 'Do you want to send Quotation to Booking CM?'
    case EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_STAFF:
      return 'Do you want to send notice to Staff?'
    case EMAIL_TYPE.OT_QUOTATION_CANCELLATION_TO_CM:
      return 'Do you want to send notice to Booking CM?'
    case EMAIL_TYPE.SEND_INVOICE_TO_OPERATOR:
      return 'Do you want to send notice to Booking Law Firm?'
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CM:
      return 'Do you want to send booking confirmation to Booking CM?'
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_STAFF:
      return 'Do you want to send booking confirmation to Staff PIC?'
    case EMAIL_TYPE.BOOKING_CONFIRMATION_TO_CLIENT:
      return 'Do you want to send booking confirmation to Client?'
    case EMAIL_TYPE.SERVICE_AGREEMENT_TO_CLIENT:
      return 'Do you want to send service agreement to Participant?'
    case EMAIL_TYPE.SERVICE_AGREEMENT_TO_CM:
      return 'Do you want to send service agreement to Support Coordinator?'
    case EMAIL_TYPE.ICARE_MATTER_TO_STAFF:
      return 'Do you want to send iCare report reminder to Staff PIC?'
    case EMAIL_TYPE.ICARE_MATTER_TO_SPECIALIST:
      return 'Do you want to send iCare report reminder to Specialist?'
    case EMAIL_TYPE.ATTENDANCE_STATUS_REMINDER_TO_SPECIALIST:
      return 'Do you want to send attendance reminder to Specialist?'
    default:
      return ''
  }
}

export type CASE_STATUS_ENUM = {
  NOT_ATTEND: string
  CASE_CREATED: string
  APPOINTMENT_REQUESTED: string
  APPOINTMENT_CONFIRMED: string
  PAPERWORK_RECEIVED: string
  // BRIEF: string
  FILE_REVIEW: string
  ATTENDED: string
  OT_ATTEND: string
  DICTATION_RECEIVED: string
  REPORT_DRAFT_RECEIVED: string
  REPORT_RETURNED: string
  REPORT_WRITING: string
  FUTHER_MATERIAL_REVIEW: string
  POST_ASSESSMENT_ADMIN: string
  QUALITY_ASSURANCE: string
  REPORT_FINALISATION: string
  REPORT_COMPLETED: string
  CANCELLED_NO_FEE: string
  CANCELLED_WITH_FEE: string
  CANCELLED_SPECIALIST_TBP: string
  CLIENT_INVOICED: string
  SPECIALIST_INVOICED: string
  PARTIALLY_PAID: string
  FULLY_PAID: string
  CLIENT_PARTIALLY_PAID: string
  SPECIALIST_PARTIALLY_PAID: string
  CLIENT_FULLY_PAID: string
  SPECIALIST_FULLY_PAID: string
  CLARIFICATION_NO_FEE: string
  ONHOLD: string
}
export const REFERENCE_UPLOAD_TYPE_ENUM = {
  CaseNo: 'CaseNo',
  ClaimNo: 'ClaimNo',
  Other: 'Other',
}

export const TENANT_EDITION = {
  MLP: 'MLP',
  LAW_CONNECT: 'LAW CONNECT',
  FUNDING_PROVIDER: 'FUNDING PROVIDER',
  LMQ_PROVIDER: 'LMQ PROVIDER',
  MARKETING: 'MARKETING',
}
export const CLINIC_TYPE = [
  { displayName: 'MLP Owned Clinic', id: 'OPERATOR' },
  { displayName: 'Specialist-Owned Clinic', id: 'SPECIALIST' },
]

export const COMMISSION_RATE_TYPE = [
  { id: 'OPERATOR', displayName: 'MLP Add-On Rate' },
  { id: 'NON_OPERATOR', displayName: 'MLP Standard Rate' },
  { id: 'GROSS_MARGIN_COT', displayName: 'Gross Margin COT' },
  { id: 'FIXED_AMOUNT', displayName: 'Fixed Amount' },
]

export enum GENDER_TYPE {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
}

export const GENDER_OPTIONS = [
  {
    id: GENDER_TYPE.MALE,
    displayName: 'Male',
  },
  {
    id: GENDER_TYPE.FEMALE,
    displayName: 'Female',
  },
  {
    id: GENDER_TYPE.NON_BINARY,
    displayName: 'Non-Binary',
  },
  {
    id: GENDER_TYPE.PREFER_NOT_TO_SAY,
    displayName: 'Prefer not to say',
  },
]

export const REGISTER_OPTION = [
  { id: 'P', displayName: 'P' },
  { id: 'D', displayName: 'D' },
  { id: 'P_AND_D', displayName: 'P & D' },
]

export const REGISTER_AS = [
  { id: 1, displayName: 'P' },
  { id: 2, displayName: 'D' },
  { id: 3, displayName: 'P & D' },
  { id: 4, displayName: 'Unknown' },
]

export const SUBSCRIBE_TO_PROMOTIONS = [
  { id: null, displayName: 'All' },
  { id: true, displayName: 'Yes' },
  { id: false, displayName: 'No' },
]

export enum EMAIL_TEMPLATE_GROUP {
  Assessment = 'Assessment',
  FileReview = 'FileReview',
  Supplementary = 'Supplementary',
  MVA = 'MVA',
  MedicalRecord = 'MedicalRecord',
  Payment = 'Payment',
  MedNeg = 'MedNeg',
  NDIS = 'NDIS',
  Disbursement = 'Disbursement',
  Account = 'Account',
  System = 'System',
}

export const CREDIT_TERM = [
  { id: 'STANDARD', displayName: 'Standard' },
  { id: 'PREPAYMENT_REQUIRED', displayName: `${PrepaymentLabel} Required Before Booking` },
  { id: 'DISBURSEMENT_APPROVED', displayName: 'Disbursement Funding Approved' },
]
export enum CASE_PERMISSION_TYPE {
  EVERYONE = 0,
  STAFF = 1,
  GROUP = 2,
}

export const CASE_PERMISSION_OPTION = [
  { id: CASE_PERMISSION_TYPE.EVERYONE, displayName: 'Everyone' },
  { id: CASE_PERMISSION_TYPE.STAFF, displayName: 'Staff' },
  { id: CASE_PERMISSION_TYPE.GROUP, displayName: 'Group' },
]

export enum PAYEE_TYPE {
  Borrower = 1,
  Specialist = 0,
  Company = 2,
  Provider = 3,
}

export const PAYEE_TYPE_OPTION = [
  { id: PAYEE_TYPE.Provider, displayName: 'Service Provider' },
  { id: PAYEE_TYPE.Borrower, displayName: 'Borrower' },
  // { id: PAYEE_TYPE.Specialist, displayName: 'Specialist' },
  // { id: PAYEE_TYPE.Company, displayName: 'Company' },
]

export const CONSULTATION_REQUESTED_TYPE = [
  { id: 'VIDEO', displayName: 'Video Consultation Requested' },
  { id: 'PHONE', displayName: 'Phone Consultation Requested' },
]

export const PHONE_NUMBER_TYPE = [
  { id: 'CLIENT_MOBILE', displayName: 'Client Mobile' },
  { id: 'CLIENT_PHONE', displayName: 'Client Phone' },
  { id: 'CLIENT_OTHER', displayName: 'Other Number' },
]
export enum DICTATION_TYPING_TYPE {
  NotSure = 'NotSure',
  Operator = 'Operator',
  Specialist = 'Specialist',
  Both = 'Both',
}
export const DICTATION_TYPING_BY_OPTION = [
  { id: DICTATION_TYPING_TYPE.Operator, displayName: 'MLP' },
  { id: DICTATION_TYPING_TYPE.Specialist, displayName: 'Specialist' },
  { id: DICTATION_TYPING_TYPE.NotSure, displayName: 'Unsure' },
  { id: DICTATION_TYPING_TYPE.Both, displayName: 'Both' },
]

export const CLINIC_TYPE_OPTION = [
  { id: CENTRE_OPTION.OPERATOR, displayName: 'MLP-Owned Clinic' },
  { id: CENTRE_OPTION.SPECIALIST, displayName: 'Specialist-Owned Clinic' },
  { id: CENTRE_OPTION.OBTAIN, displayName: 'Obtained Centre' },
  { id: CENTRE_OPTION.HOME, displayName: 'Home Assessment' },
]

export enum ONETIME_ACCESS_TYPE {
  PAPERWORK = 0,
  MR_FILE = 1,
  UNSUBSCRIBE = 2,
}

export enum CONTRACTOR_TYPE {
  TYPIST = 'TYPIST',
  BROKER = 'BROKER',
  INTERPRETER = 'INTERPRETER',
}

export const CONTRACTOR_TYPE_OPTIONS = [
  { id: CONTRACTOR_TYPE.TYPIST, displayName: 'Typist' },
  { id: CONTRACTOR_TYPE.BROKER, displayName: 'Broker' },
  { id: CONTRACTOR_TYPE.INTERPRETER, displayName: 'Interpreter' },
]
export const REVIEW_STATUS_OPTIONS = [
  { id: 0, displayName: 'Under Review' },
  { id: 1, displayName: 'Accepted' },
  { id: 2, displayName: 'Rejected' },
]
export enum RATE_TYPE {
  FIXED = 'FIXED',
  HOURLY = 'HOURLY',
  OTHER = 'OTHER',
}

export const RATE_TYPE_OPTIONS = [
  { id: RATE_TYPE.FIXED, displayName: 'Fixed' },
  { id: RATE_TYPE.HOURLY, displayName: 'Hourly' },
  { id: RATE_TYPE.OTHER, displayName: 'Other' },
]

export enum SYSTEM_INVOICE_STATUS {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  PARTIAL_PAY = 'PARTIAL_PAY',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
}

export const SYSTEM_INVOICE_STATUS_OPTION = [
  {
    id: null,
    displayName: 'All',
  },
  {
    id: SYSTEM_INVOICE_STATUS.DRAFT,
    displayName: 'Created',
    // disable: !appStore.isHostTenant,
  },
  {
    id: SYSTEM_INVOICE_STATUS.SENT,
    displayName: 'Sent',
  },
  {
    id: SYSTEM_INVOICE_STATUS.PARTIAL_PAY,
    displayName: 'Partial Pay',
  },
  {
    id: SYSTEM_INVOICE_STATUS.PAID,
    displayName: 'Paid',
  },
  {
    id: SYSTEM_INVOICE_STATUS.CANCELLED,
    displayName: 'Cancelled',
  },
]

export enum AGREEMENT_FUNDING_TYPE {
  DISBURSEMENT = 0,
  LUMP_SUMP_LOAN = 1,
  CASE_BASED_LOAN = 2,
}

export enum REPAYMENT_METHOD {
  MOTHLY = 0,
  ONE_OFF = 1,
}

export const AGREEMENT_FUNDING_TYPE_OPTIONS = [
  {
    id: AGREEMENT_FUNDING_TYPE.DISBURSEMENT,
    displayName: 'Disbursement',
    description:
      'Funding made specific to a case, repayment amount pre-calculated at a fixed due date, no interest applies',
  },
  {
    id: AGREEMENT_FUNDING_TYPE.CASE_BASED_LOAN,
    displayName: 'Case-Based Loan',
    description:
      'Funding made specific to a case, no fixed due date, interest applies, payment made against each funding case',
  },
  {
    id: AGREEMENT_FUNDING_TYPE.LUMP_SUMP_LOAN,
    displayName: 'Lump-Sum Loan',
    description:
      'Funding made as a lumsum not specific to any cases, interest applies, no fixed due date, repayment made against the whole loan',
  },
]

export const REPAYMENT_METHOD_OPTIONS = [
  {
    id: REPAYMENT_METHOD.MOTHLY,
    displayName: 'Monthly Repayment',
    disableItem: false,
  },
  {
    id: REPAYMENT_METHOD.ONE_OFF,
    displayName: 'One-Off Repayment',
    disableItem: false,
  },
]

export const PENALTY_FOR_LATE_PAYMENT_OPTIONS = [
  { id: 0, displayName: '$' },
  { id: 1, displayName: '%' },
]

export enum INTEREST_TYPE {
  Simple = 0,
  Compounding = 1,
}

export const INTEREST_TYPE_OPTIONS = [
  { id: INTEREST_TYPE.Simple, displayName: 'Simple' },
  { id: INTEREST_TYPE.Compounding, displayName: 'Compound' },
]

export enum INTEREST_RATE {
  MOTHLY = 0,
  ANNUALY = 1,
}

export const INTEREST_RATE_OPTIONS = [
  { id: INTEREST_RATE.MOTHLY, displayName: 'Monthly' },
  { id: INTEREST_RATE.ANNUALY, displayName: 'Annually' },
]

export enum NDIS_SERVICE_ITEM_TYPE {
  PriceLimitedSupports = 0,
  QuotableSupports = 1,
  UnitPrice1 = 2,
}
export const NDIS_SERVICE_ITEM_TYPE_OPTIONS = [
  { id: NDIS_SERVICE_ITEM_TYPE.PriceLimitedSupports, displayName: 'Price Limited Supports' },
  { id: NDIS_SERVICE_ITEM_TYPE.QuotableSupports, displayName: 'Quotable Supports' },
  { id: NDIS_SERVICE_ITEM_TYPE.UnitPrice1, displayName: 'Unit Price = $1' },
]

export const ACCOUNTING_APP_UPLOAD_OPTIONS = [
  {
    id: null,
    displayName: 'All',
  },
  {
    id: 'ClientUploaded',
    displayName: 'Provider Bill - Uploaded',
  },
  {
    id: 'ClientNotUploaded',
    displayName: 'Provider Bill - Not Uploaded',
  },
  {
    id: 'SpecialistUploaded',
    displayName: 'Customer Invoice - Uploaded',
  },
  {
    id: 'SpecialistNotUploaded',
    displayName: 'Customer Invoice - Not Uploaded',
  },
]
export const NDIS_SERVICE_ITEM_UNIT_OPTIONS = [
  { id: 0, displayName: 'H' },
  { id: 1, displayName: 'E' },
  { id: 2, displayName: 'D' },
  { id: 3, displayName: 'WK' },
  { id: 4, displayName: 'YR' },
  { id: 5, displayName: 'MON' },
]

export enum NDIS_SERVICE_ITEM_REMOTE_TYPE {
  REMOTE,
  VERY_REMOTE,
  STANDARD,
}

export const NDIS_SERVICE_ITEM_REMOTE_TYPE_OPTIONS = [
  { id: NDIS_SERVICE_ITEM_REMOTE_TYPE.STANDARD, displayName: 'Standard' },
  { id: NDIS_SERVICE_ITEM_REMOTE_TYPE.REMOTE, displayName: 'Remote' },
  { id: NDIS_SERVICE_ITEM_REMOTE_TYPE.VERY_REMOTE, displayName: 'Very Remote' },
]

export enum NDIS_PAYING_PARTY {
  SELF_MANAGED,
  PLAN_MANAGED,
  NDIA,
}
export const NDIS_PAYING_PARTY_OPTIONS = [
  { id: NDIS_PAYING_PARTY.SELF_MANAGED, displayName: 'Self-Managed' },
  { id: NDIS_PAYING_PARTY.PLAN_MANAGED, displayName: 'Plan-Managed' },
  { id: NDIS_PAYING_PARTY.NDIA, displayName: 'NDIA' },
]

export const ICARE_REPORT_CAPACITY_OPTIONS = [
  { id: 0, displayName: 'Capacity Identified/Functional Tolerances and Restrictions Provided' },
  { id: 1, displayName: 'Capacity Identified/Functional Tolerances and Restrictions Not Provided' },
  { id: 2, displayName: 'Capacity Not Identified/Functional Tolerances and Restrictions Provided' },
  { id: 3, displayName: 'Capacity Not Identified/Functional Tolerances and Restrictions Not Provided' },
  { id: 4, displayName: 'Not applicable' },
]

export const ICARE_REPORT_DIAGNOSIS_OPTIONS = [
  { id: 0, displayName: 'Diagnosis confirmed' },
  { id: 1, displayName: 'Alternative diagnosis provided' },
  { id: 2, displayName: 'No alternative diagnosis provided' },
  { id: 3, displayName: 'Not applicable' },
]

export const ICARE_REPORT_LIABILITY_OPTIONS = [
  { id: 0, displayName: 'Liability Acceptance Recommended' },
  { id: 1, displayName: 'Liability Acceptance Not Recommended' },
  { id: 2, displayName: 'Not applicable' },
]
export const ICARE_REPORT_TREATMENT_OPTIONS = [
  { id: 0, displayName: 'Supported' },
  { id: 1, displayName: 'Not supported' },
  { id: 2, displayName: 'Not applicable' },
]

export const ICARE_REPORT_WPIT_OPTIONS = [
  { id: 0, displayName: '0%-10%' },
  { id: 1, displayName: '11%-20%' },
  { id: 2, displayName: '21%-30%' },
  { id: 3, displayName: '31%+' },
  { id: 4, displayName: 'Not applicable' },
]

export const ICARE_REPORT_PSY_WPIT_OPTIONS = [
  { id: 0, displayName: 'Yes' },
  { id: 1, displayName: 'No' },
  { id: 2, displayName: 'Not applicable' },
]
export const ICARE_REPORT_IMC_OPTIONS = [
  { id: 0, displayName: 'Capacity Identified & NTD agreement achieved' },
  { id: 1, displayName: 'Capacity Identified & NTD agreement not achieved' },
  { id: 2, displayName: 'Capacity Identified & NTD agreement not required' },
  { id: 3, displayName: 'Capacity Identified & NTD unable to be contacted' },
  { id: 4, displayName: 'Capacity Not Identified' },
  { id: 5, displayName: 'Not applicable' },
]

export const REGISTER_AS_OPTIONS = [
  { id: 1, displayName: 'Applicants (P)', shortname: 'P' },
  { id: 2, displayName: 'Insurers (D)', shortname: 'D' },
  { id: 3, displayName: 'Both (P & D)', shortname: 'P & D' },
  { id: 4, displayName: 'Unknown', shortname: '' },
]

export const INTERPRETER_BOOKING_STATUS_OPTIONS = [
  { id: 'NONE', displayName: 'None' },
  { id: 'CONFIRMED', displayName: 'Confirmed' },
  {
    id: 'CANCELLED_NO_FEE',
    displayName: 'Cancelled (No Fee)',
  },
  {
    id: 'CANCELLED_WITH_FEE',
    displayName: 'Cancelled (Chargeable)',
  },
]

export const INTERPRETER_BOOKED_BY_OPTIONS = [
  { id: true, displayName: 'MLP' },
  { id: false, displayName: 'Booking Company' },
]

export const CAUSATION_QUALITY_CHECK_OPTIONS = [
  { id: 0, displayName: 'Adequate' },
  { id: 1, displayName: 'Inadequate' },
  { id: 2, displayName: 'Unclear' },
]
export const ATTACHMENT_LIST_AUDIO_TYPE = ['mp3', 'wav', 'wma', 'ogg', 'm4a', 'aac', 'flac']
export const ATTACHMENT_LIST_IMAGE_TYPE = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg']
export const ATTACHMENT_LIST_VIDEO_TYPE = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm']
export const ATTACHMENT_LIST_DOCUMENT_TYPE = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'txt',
  'rtf',
  'csv',
  'zip',
  'rar',
]
export enum VIDEO_APP_ENUM {
  OPERATOR = 'OPERATOR',
  OTHERS = 'OTHERS',
}
export const STATUS_LABEL = [
  { displayName: `All`, id: null },
  { displayName: `Active`, id: true },
  { displayName: `Inactive`, id: false },
]

export enum SPECIALIST_STATUS_ENUM {
  ACTIVE,
  INACTIVE,
  WITH_CLINIC,
  WITHOUT_CLINIC,
}

export enum PROVIDER_TYPE_ENUM {
  SPECIALIST = 'SPECIALIST', // SelectedItemFees = true
  SPECIFIC_ADMIN = 'SPECIFIC_ADMIN', //Invoice-Item (MLP) - SelectedOperator = true
  GENERAL_ADMIN = 'GENERAL_ADMIN', //Non-Invoice-Item (MLP) - when print invoice -> add to first service item
  GENERAL_ADMIN_2 = 'GENERAL_ADMIN_2', // show in invoice item list
}

export enum SESSION_STATUS_ENUM {
  AVAILABLE = 'Available',
  PENDING = 'Pending',
  UNAVAILABLE = 'Unavailable',
}

export const APPOINTMENT_TYPE_OPTIONS = [
  { id: null, displayName: 'All' },
  { id: 0, displayName: 'In-person' }, //show nhung case co Require Telehealth = No
  { id: 1, displayName: 'Telehealth ' }, //show nhung case co Require Telehealth = Yes
]

export const RECRUITMENT_STATUS_ENUM = {
  RECRUITING: 0,
  ONBOARDING: 1,
  ONBOARDED: 2,
  EXCLUDING: 3,
}
export const RECRUITMENT_STATUS_OPTIONS = [
  { displayName: 'Recruiting', id: 0, statusName: 'RECRUITING' },
  { displayName: 'Onboarding', id: 1, statusName: 'ONBOARDING' },
  { displayName: 'Onboarded', id: 2, statusName: 'ONBOARDED' },
  { displayName: 'Excluding', id: 3, statusName: 'EXCLUDING' },
  { displayName: 'Non-Booking Contact', id: 4, statusName: 'NON_BOOKING_CONTACT' },
]

export const PREPAYMENT_FROM_BOOKING_COMPANY_STATUS_OPTIONS = [
  { id: null, displayName: 'All' },
  { id: 0, displayName: 'Prepayment Required' },
  { id: 1, displayName: 'Prepayment Paid' },
]
export const PREPAYMENT_TO_MR_PROVIDER_STATUS_OPTIONS = [
  { id: null, displayName: 'All' },
  { id: 0, displayName: 'Prepayment Required' },
  { id: 1, displayName: 'Prepayment Paid' },
]

export const VIDEO_OPTIONS = [
  { id: 'OPERATOR', displayName: 'Kawaconn Video' },
  { id: 'OTHERS', displayName: 'Zoom, Teams, Google Meet, ...' },
]

export const REPRESENTATIVE_RELATION_TO_CLIENT_OPTIONS = [
  { id: 0, displayName: 'Parent and/or Child Representative' },
  { id: 1, displayName: 'Plan Nominee' },
  { id: 2, displayName: 'Legally Appointed Decision Maker' },
  { id: 3, displayName: 'Other' },
]

export const REPRESENTATIVE_VERIFIED_OPTIONS = [
  { id: 0, displayName: 'NDIA Portal' },
  { id: 1, displayName: 'NDIS Plan' },
  { id: 2, displayName: 'Other Documentation' },
]

export const SUPPORT_COORDINATOR_VERIFIED_OPTIONS = [
  { id: 0, displayName: 'NDIA Portal' },
  { id: 1, displayName: 'NDIS Plan' },
  { id: 2, displayName: 'Verbal' },
  { id: 3, displayName: 'Other' },
]

export const FUNDING_MANAGER_TYPE = [
  { id: 0, displayName: 'NDIA Managed' },
  { id: 1, displayName: 'Plan Managed' },
  { id: 2, displayName: 'Self Managed' },
]
export const CONSENT_TYPE_OPTIONS = [
  { id: 0, displayName: 'Expressed' },
  { id: 1, displayName: 'Inferred' },
]
export const TRANSACTION_SERVICE_TYPE_OPTIONS = [
  { id: FROM_MODULE_TYPE.IME, displayName: 'IME' },
  { id: FROM_MODULE_TYPE.FR, displayName: 'FR' },
  { id: FROM_MODULE_TYPE.FR_TELECONFERENCE, displayName: 'FR (Teleconference)' },
  { id: FROM_MODULE_TYPE.SUPP, displayName: 'SUPP' },
  { id: FROM_MODULE_TYPE.MN, displayName: 'MN' },
  { id: FROM_MODULE_TYPE.MN_TELECONFERENCE, displayName: 'MN (Teleconference)' },
  { id: FROM_MODULE_TYPE.MR, displayName: 'MR' },
  { id: FROM_MODULE_TYPE.INVOICE, displayName: 'INVOICE' },
  { id: FROM_MODULE_TYPE.OTHER, displayName: 'Other (Non-Report)' },
]
